import React from 'react'

function MainActivities({NavigationButtons}) {
  const data = [
    {
      id : '1',
      title : 'PROJECTS SHOWCASE'
    },
    {
      id : '2',
      title : 'DOING BUSINESS OVERVIEW'
    },
    {
      id : '3',
      title : 'ECONOMIC MISSION REPORT'
    },
    {
      id : '4',
      title : ' INVESTMENT VÉHICULE'
    },
    {
      id : '5',
      title : 'INVESTMENT INCENTIVE'
    },
    {
      id : '6',
      title : 'FINANCIAL GOOD GOVERNANCE PRACTICES'
    },
    {
      id : '7',
      title : ' FINANCING INSTRUMENTS'
    },
    {
      id : '8',
      title : 'COUNTRY PROFILE'
    },
    {
      id : '9',
      title : ' PRIVATE SECTOR SPACE'
    },
    {
      id : '10',
      title : 'FDI ROADMAP'
    },
    {
      id : '11',
      title : '"AFRICA ECONOMY RESILIENT AWARD POST COVID-19"'
    },
    {
      id : '12',
      title : 'GOVERNMENT SPACE'
    },
    {
      id : '13',
      title : 'INTERVIEWS'
    },
  ]
  return (
   <>
    <div className='strategicwork-container'>
      <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/insights/mainActivities.png" alt="Logo" className='main-activity-image' />
      <div className='strategicwork-heading'>
        <span className='strategicwork-numbering'>2</span>
        <span className='strategicwork-data'>Main Activities on the Platform</span>
      </div>
      <div className='mainActivities-grid'>
        {data.map((goal) => (
          <div key={goal.id} className='mainActivities-item'>
            {goal.title}
          </div>
        ))}
      </div>
    </div>
    <NavigationButtons 
        showPrev={true}
        showNext={true}
        prevAddress={'strategicwork'}
        nextAdress={'fundraisingplan'}/>
   </>
  )
}

export default MainActivities