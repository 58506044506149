import React from 'react'

function EconomicDevelopment() {
  return (
    <div className='subheading-content-wrapper'>
      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>1</span>
        <span className='fundraising-body-data'>Ensure that fundraising strategy is investment-ready for the community: FICA would ensure that it is well-prepared to accommodate new investment through the availability of opportunities within both existing needs and/or projects availability for development with proper infrastructure.
          The fundraising strategy addresses the investment issues and the other major components of readiness with respect to the Countries' Development Plan.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>2</span>
        <span className='fundraising-body-data'>Identify key target sectors and markets: A Program is proposed to be directed at both domestic and off-shore investments based on target sectors and markets. Targets and major companies are identified for each of the Countries's target sectors for “easy to follow” implementation by Countries officials and staff.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>3</span>
        <span className='fundraising-body-data'>FICA Marketplace for investment: The strategy identifies the key marketing materials and initiatives that are necessary for Countries to achieve its objective of increasing foreign direct investment.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>4</span>
        <span className='fundraising-body-data'>Track, work with and secure the investment: The strategy assesses at a high level. Countries's current practices to ensure investment inquiries are monitored and handled in a timely and expeditious manner.</span>      
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>5</span>
        <span className='fundraising-body-data'>Provide after-sales service: The strategy recognizes the importance of managing relationships with existing companies to ensure their assistance in attracting new businesses.</span>
      </div>

      <p className='ending-data'>The results of our analysis and the fundraising strategy and Action Plan will be presented in the report that follows. Provided under separate cover is a long-list of target corporations in Country's key economic sectors.</p>
    </div>

  )
}

export default EconomicDevelopment