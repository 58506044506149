import React from 'react'

function ActionPlan() {
  return (
    <>
      <div className='padding-4'>
        <p>
        Although technical and professional training can bridge the gaps left by academic and other non-market-driven training, it is a continuing challenge to emphasize technical and professional training on skills and capacity development for agriculture and agribusiness, leather and textile as well as livestock within the ever-changing modern society with its challenges and opportunities. Creating a Center of Excellence for Capacity Development (CECDA) remains the indispensable strategy for the much-needed transformation of African socio-economic conditions. Capacity development is a springboard for Africa's transformations and this Center will therefore bridge the critical skills gaps resulting in more employment opportunities in and increased income from agriculture and agribusiness, leather and textile, and livestock, particularly for women and youth. Bringing on board various public-private sector stakeholders, the Center will be an innovation hub for skills development needed for the transformation of agriculture and agribusiness, leather and textile, and livestock.
        </p>
        <h2>Benefits</h2>
      </div>

      <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 300,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        backgroundColor: '#E9F6FD', padding: ''}}
      className='padding-4'>
        <p style={{fontWeight: '500', padding: '2rem'}}>
          The starting point is the creation of the Center of Excellence for Capacity Development in Africa, headquartered in Ethiopia. The current literature indicates that the following constitute challenges for job creation and income generation for women and young people in Ethiopia and elsewhere in Africa:
        </p>
        <ul>
          <li>Africa's youth population is rapidly increasing</li>
          <li>Youth employment challenge in Africa also has global implications</li>
          <li>The majority of women and youth in Africa do not have stable economic opportunities.</li>
          <li>The majority of women/youth employed are engaged in the informal economy, with its own challenges</li>
          <li>Lack of opportunity affects women and youth across all geographies and demographic segments, with variations in the nature of the challenge increasing its complexity</li>
          <li>Women, rural youth, and those that live in fragile states are particularly impacted</li>
        </ul>
      </div>
      <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 300,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        paddingBottom: '2rem',
        backgroundColor: '#E9F6FD'}}
      className='padding-4'>
        <p style={{fontWeight: '500', padding: '2rem'}}>
        Evidence suggests that without a government and therefore a political champion, PPPs rarely succeed. In this case, the Federal Cooperative Agency in Ethiopia will ensure negotiations and consensus building to develop a common understanding for the creation of the Center of Excellence for Capacity development. FCA, ACBF and FICA will bring together public and private stakeholders:
        </p>
        <div className='padding-sm' style={{paddingLeft: '2rem'}}>
          <p>1. In the design of the Center for skills building</p>
          <p>2. In the implementation of the Center activities</p>
        </div>
        <ul>
          <li style={{fontWeight: '700'}}>
            In reducing inequitable access to technical and professional skills building:
            <p>1. In creating public-private linkages for job creation, and income generation</p>
            <p>2. In transforming agriculture, agribusiness, leather and textile and livestock</p>
          </li>
        </ul>
      </div>

      <div className='padding-4' 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 300,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px'}}>
        <h2>Rationale</h2>
        <p>It is obvious that PPP arrangements are vital in driving socio-economic growth by providing well-planned, well-funded, and well-maintained capacity development facilities providing skills and competencies needed in the market-driven agriculture and livestock. This is much significant for transforming market-oriented agriculture, agribusiness, leather and textile industry, and livestock to raise the living conditions of the people. Increasingly, African governments are interested in PPP arrangements as alternative vehicles for mobilizing resources to develop the capacity needed to deliver on SDGs and Agenda 2063. These arrangements have for example significantly contributed to the GDP growth of countries like Nigeria, Ghana, South Africa, Mozambique, and Kenya..</p>
        <p>The project under consideration is based on the premises that successful PPPs require good transaction skills on the part of the government partner (including legal, financial, negotiation and industry specific skills) as well as an experienced skills' provider from the private sector; and that projects with good business opportunity are more attractive to private partners. Despite the growing interest in the PPP model across the globe and attempts to tap on PPP initiatives, its adoption in Ethiopia and in other African countries remains limited and fragmented, more so in capacity development for agriculture and agribusiness, leather and textile industry, and livestock.</p>

        <h2>PAGE PRIVATE SECTORS AND CIVIL SOCIETY</h2>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '1rem'}}>
          <li>Providing different services to members and the business community</li>
          <li>Conducting training and other capacity building programs,</li>
          <li>Providing market information, stage trade fairs,</li>
          <li>Safeguarding the overall rights and benefits of members and the business community</li>
          <li>Promoting and publicize Ethiopian products and services</li>
          <li>Enhance Trade and Investment of the country</li>
          <li>Serving as a bridge between the business community and the Government.</li>
        </ul>

        <h2>OBJECTIVES OF THE ACADEMY</h2>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '2rem'}}>
          <li>To strengthen the leadership and management skills of the Ethiopian Business Membership Organizations (BMOs) especially Chambers and Sector-based Associations.</li>
          <li>To create association management professionalism and certification program</li>
          <li>To create platforms to share best practices and experiences among Ethiopian Chambers, Associations and other membership based organizations on areas of Leadership and Management activities.</li>
        </ul>

        <h2>3.3.3. ORGANIZATIONAL PROFILE</h2>
        <p>The ECA has competent, well experienced and receptive staff in-house who are responsible for organizing training, following and monitoring training events and are always on alert to provide support to those in need of capacity-building training:</p>
        <ul style={{padding: '2rem', paddingTop: '0', paddingLeft: '2rem'}}>
          <li>The Academy has maintained a network of industry experts, practitioners and consultants with solid professional experiences and educational backgrounds to engage in training delivery.</li>
          <li>The ECA has got ideally located training room on ECCSA premises with all the requisite facilities and training resources.</li>
          <li>The training programs that ECA provides has been closely supported by CIPE, an affiliate of the U.S. Chamber of Commerce, and through it networked with IOM which has a century old experience in the provision of courses to various chambers and associations members throughout the USA.</li>
          <li>The ECA is becoming the Hub in Leadership and Governance training across the business sector.</li>
          <li>The Academy has got an Advisory Board established to oversee if the academy delivers the best value for money.</li>
        </ul>

        <h2>3.3.3. ORGANIZATIONAL PROFILE</h2>
          <p>Skills development is key to Agriculture, agribusiness, leather, textile industry, livestock, technical and professional training, job creation, and income generation through fostered women and youth entrepreneurship. Skill development can include targeted education and training programs to promote women and youth in business.</p>
          <p>Tailor-made training modules for women and youth need to be devised to address their specific needs with a view to helping them become self-reliant and contribute to boosting local economies. It is against this background that this concept note has been prepared.</p>
          <p>ECCSA's objectives are in line with the general and specific objectives of the International Federation of Consular Chambers for Africa (FICA) Project in promoting and supporting women and youth in business and in capacity development of ECCSA's members.</p>
          <p>However, ECCSA has financial gap to undertake the following activities under the Women and Youth Business Support and Promotion Division and under the Chamber Academy.</p>
        
        <h2>Important Points To Know</h2>
      </div>

      <div style={{backgroundColor: '#E9F6FD', padding: '3rem', paddingLeft: '7rem', fontWeight: '800'}} className='padding-4 padding-4-sm'>
        <p style={{fontWeight: '800'}}><i>African Development Bank, Jobs for Youth in Africa - Strategy for Creating 25 Million Jobs and Equipping 50 Million Youth 2016-2025</i></p>
        <p style={{fontWeight: '800'}}><i>Youth are defined as ages 15-35 based on the African Union definition. Data from United Nations Population Division, “World Population Prospects: the 2015 Revision”</i></p>
        <p style={{fontWeight: '800'}}><i>UNDP, Prospects of Public-Private Partnership (PPP) in Ethiopia. Development Brief No. 1/2015</i></p>
      </div>
    </>
  )
}

export default ActionPlan