import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './App';

import './index.css';
import reportWebVitals from './reportWebVitals';

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
          <App />
        </BrowserRouter>
    </React.StrictMode>
  );
}

renderApp();
reportWebVitals();
