import React, { useEffect } from 'react';
import { StrategicOperationalWork, MainActivities, FundRaisingPlain, OperationalPlan } from '../components';
import { useParams, useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom'
import {LeftCircleFilled, RightCircleFilled} from '@ant-design/icons'

const NavigationButtons = ({ showPrev, showNext, prevAddress, nextAdress }) => {
  return (
    <div className="button-container">
      {showPrev && (
        <Link to={`/insights/${prevAddress}`} className='nav-button prev-button'>
          <LeftCircleFilled style={{color:'#000000', fontSize: '2rem'}}/> PREVIOUS
        </Link>
      )}
      {showNext && (
        <Link to={`/insights/${nextAdress}`} className='nav-button next-button'>
          NEXT <RightCircleFilled style={{color:'#000000', fontSize: '2rem'}}/>
        </Link>
      )}
    </div>
  );
};


function Insights() {
  let { id } = useParams();

  const navigate = useNavigate()
  
  useEffect(() => {
    scorllToTop()
    if(id !== 'strategicwork' && id !== 'mainactivities' && id !== 'fundraisingplan' && id !== 'operationalplan') {
      navigate('/not-found')
    }
  }, [id])

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  return (
    <div>
      {id === "strategicwork" ? (
        <StrategicOperationalWork NavigationButtons={NavigationButtons}/>
      ) : id === "mainactivities" ? (
        <MainActivities NavigationButtons={NavigationButtons}/>
      ) : id === "fundraisingplan" ? (
        <FundRaisingPlain NavigationButtons={NavigationButtons}/>
      ) : id === "operationalplan" ? (
        <OperationalPlan NavigationButtons={NavigationButtons}/>
      ) : (
        <div>Invalid ID</div>
      )}
    </div>
  );
}

export default Insights;
