import React from 'react';

import { LuCircleDot } from "react-icons/lu";

const insightsData = [
  {
    id: '1',
    title: 'The countries key economic development requirements:',
    colorClass: '#3ee0f6',
    color: 'blue'
  },
  {
    id: '2',
    title: 'Benefits for the Government include:',
    colorClass: '#E1BEE7',
    color: 'blue'
  },
  {
    id: '3',
    title: 'Why does FICA need a fundraising strategy?',
    colorClass: '#F8BBD0',
    color: 'orange'
  },
  {
    id: '4',
    title: 'Three-step fundraising strategy recommended',
    colorClass: '#C8E6C9',
    color: 'green'
  },
  { 
    id: '5',
    title: 'Positioning our fundraising strategy for success FICA Strategy',
    colorClass: '#B3E5FC',
    color: 'blue'
  },
];

const InsightsCards = ({ activeCard, handleLinkClick, updateHeading, updateColor}) => {
  return (
    <div className="insights-section">
      <div className="section-heading">
        <h2>Related Contents</h2>
        {/* <span></span> */}
      </div>
      <div className="insights-cards">
        {insightsData.map((insight, index) => (
          <div
            key={index}
            className={`card ${activeCard === insight.id ? 'active' : ''}`}
            onClick={() => {
              handleLinkClick(insight.id);
              updateHeading(insight.title);
              updateColor(insight.color);
            }}
          >
            <div className="card-content">
              <p>{insight.title}</p>
            </div>
            <div className='card-footer' style={{backgroundColor: `${insight.colorClass}`}}>
              <button className="read-more">{activeCard === insight.id ? 'Now ' : 'Read '} {activeCard === insight.id ? <LuCircleDot style={{paddingLeft: '5px'}}/>: '>'}</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsightsCards;