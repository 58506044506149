import React from "react";
import { Divider } from "antd";

const JeanBosco = () => {

    const handleDownloadProflie = () => {
        const link = document.createElement('a')
        link.href = `https://fica-data.s3.ap-south-1.amazonaws.com/pdfs/Jean-Bosco-CHENDJOU.pdf`
        link.download = 'Jean-Bosco-CHENDJOU.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div id="jean-bosco-page-download">
        <div className="team-profile-hero-container">
            <div className="cirlce-bg">
                <div className="hero-image">
                    <img src={`https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/jeanBosco.jpeg`} alt="" className="team-hero-img jhon-bosco" style={{top:'-50%', left:'5%'}}/>
                </div>
            </div>
            <h2 className="title">Jean Bosco CHENDJOU</h2>
            <h3 className="designation">Project Manager Assistant</h3>
        </div>
        <div className="team-profile-body-container">
            <Divider className="label-heading" orientation="left" orientationMargin={0}>Objective</Divider>
            <p className="label-text">
                Marketing and communication professional with ten years experience / Keep learning. 
                Successful deployment of operational marketing campaigns with teams. Team spirit and leadership 
                skills to motivate teams. Effective multitasking while meeting deadlines
            </p>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>Experience</Divider>
            <div className="expirence-container">
                <div className="date-container" style={{width:'200px'}}>
                    <div className="start-date">May 2019</div>
                </div>
                <p className="label-text">
                    <strong>PROJECT MANAGER ASSISTANT - FICA (Addis Abeba,Lomé)-</strong> Project management and monitoring - Development 
                    and capacity building, with the support of the country representative.- Monitoring administrative procedures 
                </p>
            </div>
            <div className="expirence-container">
                <div className="date-container">
                    <div className="start-date">June 2018</div>
                    <div className="end-date">March 2019</div>
                </div>
                <p className="label-text">
                    <strong>OPERATIONAL MARKETING MANAGER</strong> - Negimex Cameroon- Brand Management (Kronenbourg 1664, Le Chat savon)- 
                    Setting up local marketing and sales activities with the support of suppliers.- Deployment and monitoring of BTL activities 
                    (events and product campaigns)
                </p>
            </div>
            <div className="expirence-container">
                <div className="date-container">
                    <div className="start-date">Jan 2017</div>
                    <div className="end-date">March 2018</div>
                </div>
                <p className="label-text">
                    <strong>CUSTOMER MARKETING ASSISTANT</strong> - Unilever Cameroon- Deployment of marketing activities with the support of 
                    local distributors and marketing agencies.- Merchandising management with two teams of 16 merchandisers (Modern trade and 
                    traditional trade).- Competitive intelligence abd reporting
                </p>
            </div>
            <div className="expirence-container">
                <div className="date-container">
                    <div className="start-date">Jul 2014</div>
                    <div className="end-date">Dec 2017</div>
                </div>
                <p className="label-text">
                    <strong>ACCOUNT MANAGER</strong> - Mekis Agency  Brand Management (design and deployment of BTL activities)- Customer 
                    account follow-up - Casting, training and supervision of marketing activities (events and product campaigns)
                </p>
            </div>
            <div className="expirence-container">
                <div className="date-container">
                    <div className="start-date">2008</div>
                    <div className="end-date">2010</div>
                </div>
                <p className="label-text">
                    <strong>MINI-MARKET MANAGER - GESEC SARL-</strong> Mini-market management, with the support of a team of eight people 
                    (sales assistants, cashiers and  receptionists)- Stock and supply management
                    <br/>
                    <strong>Academic and professional Internship</strong> - ICRAF- Jun/Jul 2005 : Exploratory study of non-timber 
                    forest products- Jun/Aug 2008 : Diagnostic of non-timber forest products for international trade
                </p>
            </div>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>Education</Divider>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">2018</p>
                </div>
                <p className="label-text">
                    University of Douala - Cameroon Professional master's degree 1 - Marketing, commerce and sales
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">2009</p>
                </div>
                <p className="label-text">
                    University of Dschang - Cameroon  Bachelor degree/ Marketing, Customer relationship
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">2006</p>
                </div>
                <p className="label-text">
                    Institut Siantou of Yaounde - Cameroon  Higher National Diploma / International Trade
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">2004</p>
                </div>
                <p className="label-text">
                    Collège La Réunification of Bafoussam - Cameroon  Baccalaureat/ Commerce
                </p>
            </div>

            <Divider className="label-heading" orientation="left" orientationMargin={0}>Skills</Divider>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">Professional</p>
                </div>
                <p className="label-text">
                    Customer experience - Customer relationship management - Marketing and sales - Trade marketing <br/>
                    Social marketing Team leadership - Team building - Time management International trade
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container-education" style={{width:'150px'}}>
                    <p className="start-date-education">Additional</p>
                </div>
                <p className="label-text">
                    English (Average) - French (Native language)  Good computer skills and regular use <br/>
                    (Pack Office, internet, etc.)  Football - TV series - Chill with friends
                </p>
            </div>
        </div>
        <div className="footer-banner">
            <p className="banner-text">Jean Bosco CHENDJOU</p>
            <a href="tel: +22892085601" className="banner-text">+22892085601</a>
            <a href="mailTo: chendjou@yahoo.fr" className="banner-text padding-bt-2">-chendjou@yahoo.fr</a>
            <div className="download-profile" onClick={handleDownloadProflie}>
                <img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/download-icon.png" alt="" className="download-icon" />
                <p className="download-text">Download Profile</p>
            </div>
        </div>
        </div>
    )
}

export default JeanBosco