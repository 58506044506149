import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function FicaSolution() {

  const location = useLocation()

  // useEffect(() => {
  //   scorllToTop()
  // }, [])

  useEffect(() => {
    if (location.state && location.state.scrollVal) {
      scrollToSection(location.state.scrollVal);
    } else {
      scorllToTop()
    }
  }, [location.state])

  const scrollToSection = (id) => {
    const element = document.getElementById(id)
    if (element) {
      const offset = 200
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      })

      setTimeout(() => {
        if (location.state && location.state.scrollVal === id) {
          window.history.replaceState(null, '')
        }
      }, 1000)
    }
  };

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  return (
    <>
    <div className='solution-hero'>
      <h2 className='hero-text'>FICA INNOVATIVE SOLUTION ON FINANCE AND INVESTMENT IN AFRICA</h2>
      <img loading='lazy' src='https://fica-data.s3.ap-south-1.amazonaws.com/img/ficaSolutions/solution-hero.png'/>
    </div>
    <div className='solution-hero-baner-cont'>
      <h3 className='solution-hero-baner-text'>Using the FINTECH solutions to accelerate financial inclusion in Africa</h3>
    </div>
    <div className='ficasolutions-container'>
      
      <div id='decentralizedFinance' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>1. Decentralized Finance</h2>
        <p>Decentralized finance (DeFi) builds on distributed ledger technologies (DLT) to offer services such as trading, lending and investing without using a traditional centralized intermediary. The fact that DeFi components can be programmed may open up new possibilities for more competitive financial markets and could bring efficiency gains. However, DeFi introduces enormous technological and economic complexity that makes it increasingly difficult to assess the risks and potential of DeFi financial products. But financial institutions and regulators dealing with DeFi need just such a systematic evaluation of these factors.</p>
        <p>DeFi is defined here as a competitive, contestable, composable and non-custodial financial ecosystem built on technology that does not require a central organization to operate. To explain the functioning, design and interoperability of such systems, the paper uses a DeFi stack reference (DSR) model to conceptualize the stack-layer components of their technical primitives and financial functions.</p>
      </div>
      
      <div id='smartBonds' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>2. Smart Bonds</h2>
        <p>A smart bond (or blockchain Bond) is a specific type of an automated bond contract that uses the capabilities of blockchain databases that Can operate as cryptographically-secure yet open and transparent general ledgers. This is sometimes referred to as Distributed Ledger Technology (DLT). Simply put, smart bonds are bonds that utilize blockchain technology and smart contracts. In doing so, they are able to self-execute and automate the various stages of a bond's life cycle, benefiting both issuers and investors alike. For instance, smart bonds can provide cryptographic security, near-instant clearing and settlement, reduce costly transfer fees, and improve the overall life cycle management of bonds.</p>
      </div>
      <img loading='lazy' className="ficasolutions-logo" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/ficaSolutions/smartBonds.png" alt="Logo" />
      
      <div id='eInvestment' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>3. E- Investment Insurance</h2>
        <ul className='ficasolutions-ul'>
          <li className='ficasolutions-li'>Insurance and investment are two important components of financial planning. They offer distinct benefits and cater to the financial needs.</li>
          <li className='ficasolutions-li'>Insurance provides a cover that secures investors financially in case of any unfortunate event. It provides the security of investment with a fixed amount of money in such a situation.</li>
          <li className='ficasolutions-li'>That can help the investment returns on money by investing in market-linked instruments. The money gets invested in equity and debt markets and offers returns.</li>
          <li className='ficasolutions-li'>Investment insurance offers the benefits of both insurance (through a life cover) and investment (market-linked returns). It offers an assured life cover amount in the case of an unfortunate event and, at the same time, helps to grow money and generate returns for the financial and investment goals.</li>
        </ul>
      </div>

      <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/ficaSolutions/AIM.png" alt="Logo" className='section-image' />

      <div id='smartContract' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>4. Smart Contracts And Crowdfunding</h2>
        <h4 className='ficasolutions-subheading'>Redefining Investment Opportunities</h4>
        <p style={{marginTop: '5px'}}>In recent years, crowdfunding has emerged as a transformative force in finance, reshaping how entrepreneurs and innovators raise capital for their projects. It's an innovative approach that allows individuals, often referred to as backers or investors, to collectively contribute smaller amounts of money to fund projects, businesses, or creative endeavors.This democratization has expanded investment opportunities for people from all walks of life. However, as crowdfunding has grown in popularity and evolved, it has also brought about a series of concerns that cast shadows over its potential benefits.</p>
      </div>

      <div id='basketFundAndCrowdFund' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>5. Basket Fund Crowdfunding </h2>
        <p>Basket funds are a mechanism for pooling funds from various sources, typically governments, donors and the private sector to support priorities and ensure adequate resource allocation for agreed upon program areas.
        A basket fund is a pooled fund, typically from government, donors and private sector, that is channeled into a specific sector, for example, healthcare. Basket fund participants agree upon the priorities and allocate resources to selected program areas a priority..</p>
      </div>

      <div id='tradeFinance' className='ficasolutions-content'>
        <h2 className='ficasolutions-title'>6. Trade Finance</h2>
        <p>Trade finance signifies financing for trade, and it concerns both domestic and international trade transactions. A trade transaction requires a seller of goods and services as well as a buyer. Various intermediaries such as banks and financial institutions can facilitate these transactions by financing the trade.</p>
        <p>Trade finance represents the financial instruments and products that are used by companies to facilitate international trade and commerce. ... Trade finance is an umbrella term meaning it covers many financial products that banks and companies utilize to make trade transactions feasible. Trade Finance has been reviewing the global trade and export finance markets since 1983 and what constitutes trade finance has gone from a basic letter-of-credit product to highly structured combined bond and debt financings.</p>
      </div>

    </div>
    </>
  )
}

export default FicaSolution;
