import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { InsightsCards, EconomicDevelopment, GovtBenifit, FicaStrategy, ThreeStepStrategy, SuccessStrategy } from '..';

function FundRaisingPlan({NavigationButtons}) {
  const [activeCard, setActiveCard] = useState('1')
  const [subheading, setSubheading] = useState('The countries key economic development requirements:- ')
  const [color, setColor] = useState('blue');

  useEffect(() => {
    scorllToTop()
  }, [activeCard])

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  const updateColor = (color) =>{
    setColor(color);
  };

  const handleLinkClick = (id) => {
    setActiveCard(id);
  };

  const updateHeading =(subHeading) =>{
    setSubheading(subHeading);
  }

  return (
    <>
      <div className="image-container">
        <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/insights/fundRaisingPlan.jpeg" alt="Logo" />
        <span className='fundraising-numbering'>3</span>
        <span className='fundraising-data'>Fundraising Strategic Plan</span>
      </div>

      <div className='heading-wrapper'>
        <p>The focus of the strategy is on inbound FDI from outside of Africa, including exports, although many of the recommendations can be applied to investment attraction in general. The broad context for this strategy are the key requirements identified by the African's Members States Economic Development Department.</p>
      </div>

      <div className={`subheading-wrapper ${color}`}>
        <FaStar className='star-icon' />
        <p style={{marginTop: '12px', marginBottom: '12px'}}>{subheading}</p>
      </div>

      <div>
        {activeCard === "1" ? (
          <EconomicDevelopment />
        ) : activeCard === "2" ? (
          <GovtBenifit />
        ) : activeCard === "3" ? (
          <FicaStrategy />
        ) : activeCard === "4" ? (
          <ThreeStepStrategy />
        ) : activeCard === "5" ? (
          <SuccessStrategy />
        ) :(
          <div>Invalid ID</div>
        )}
      </div>
      <div >
        <InsightsCards activeCard={activeCard} handleLinkClick={handleLinkClick} updateHeading={updateHeading} updateColor={updateColor}/>
      </div>
      <NavigationButtons 
        showPrev={true}
        showNext={true}
        prevAddress={'mainactivities'}
        nextAdress={'operationalplan'}/>
    </>
  );
}

export default FundRaisingPlan;
