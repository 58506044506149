import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PhilipUzuma = () => {

    const handleDownloadProflie = () => {
        const link = document.createElement('a')
        link.href = `https://fica-data.s3.ap-south-1.amazonaws.com/pdfs/Mr.-Philip-UZUMA-NNGANYADI.pdf`
        link.download = 'Mr.-Philip-UZUMA-NNGANYADI.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const location = useLocation()
    const navigate = useNavigate()
    const [pageNo, setPageNo] = useState(0)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const pageNo = queryParams.get('pageNo') || 0
        if(!pageNo || Number(pageNo)>3 || Number(pageNo)<1 || isNaN(Number(pageNo))) {
            navigate('/not-found')
        }
        setPageNo(Number(pageNo))
        scorllToTop()
    }, [location.search])

    const handleBack = () => {
        if (pageNo > 1) {
            const newPageNo = pageNo - 1
            setPageNo(newPageNo)
            navigate(`/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=${newPageNo}`)
        }
    }

    const handleNext = () => {
        if (pageNo < 3) {
            const newPageNo = pageNo + 1
            setPageNo(newPageNo)
            navigate(`/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=${newPageNo}`)
        }
    }

    const scorllToTop = () => {
        window.scrollTo({
            top : 0,
            behavior : "smooth"
        })
    }

    return (
        <div id="philip-uzuma-page-download">
        <div className="team-profile-hero-container">
            <div className="cirlce-bg">
                <div className="hero-image">
                    <img src={`https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/philipUzuma.jpeg`} alt="" className="team-hero-img philip-uzuma" style={{top:'-50%', left:'5%'}}/>
                </div>
            </div>
            <h2 className="title">Mr. Philip UZUMA NNGANYADI</h2>
            <h3 className="designation">General Secretariat</h3>
        </div>

        { pageNo===1 &&
        <>
        <div className="team-profile-body-container">
            <h3 className="label-sub-heading black">Former Director Engineering & Technology @ The Bright Future Project Lusaka Zambia</h3>
            <p className="label-text light">
                Guardian Europe/CRVC S.AR.L; Environmental & government affairs cum EHSS Coordinator for Europe, Central & Eastern Europe, Middle East & Africa. International Project Management Green Field Flat Glass & 
                Coater production Plants, leadership position; in over 13 (thirteen) Countries as Environmental & government affairs coordinator,  Project Administrator, Cross-functional Engineering & EPCM project 
                support services in EU-28 & CEE (Central & Eastern Europe) countries with exceptional project management skills, product knowledge, & exceptional linguistic Skills.
            </p>

            <h3 className="label-sub-heading black">Permitting Services:</h3>
            <p className="label-text light">
            Every project requires authorizations so that the project objectives can be realized. These authorizations are often related to local competent authorities, third parties’ verifications, consultants’ agencies & ministries.
            <br/><br/>
            In all countries; <strong>Europe – EU-28, Central & Eastern Europe (CEE)</strong> including Russian Federation, North America, Africa, Middle East & Asia Minor, where Guardian Europe started businesses or have businesses, 
            I took the leadership and Administrative responsibility, based on my skilled & in-depth knowledge of subject matter and requirements by the local competent authorities, Ministries & agencies; norms, standards & legislation 
            for all forms & types of Permits & Licenses. 
            <br/><br/>
            My principal assignment consisted among others in providing, elaborating project design documentation, filing & obtaining the relevant permits based on local legislation, directives & international norms, standards 
            & guidelines. Typical permits being,
            <br/>
            (i) Planning Permit<br/>
            (ii) Environmental Permit<br/>
            (iii) Building and/or Construction Permit<br/>
            (iv) Operational Permit<br/>
            (v) License to Operate, Storage and Handling of Hazardous Production Objects.<br/>
            </p>

            <h3 className="label-sub-heading black">Project Administrator/Manager: Rostov – on Don</h3>
            <p className="label-text light">
                (Russian the Federation) 900 t/d Float Glass & Coater Green Field project. Responsibilities includes but not limited to Land lease Agreements (LLA), choice/negotiation of/with consultants & EPCM Partners/contractors & 
                sub-contractors; local & federal regulatory enforcement competent authorities in the Russian the Federation.
                <br/><br/>
                Specification, <strtong>Design, Build, Own, operate (DBOO); and Transfer (T)</strtong> of Guardian’s 2nd... Float glass & coater production plant Rostov Russian, the first have been built in Ryazan. Elaboration and specification of 
                project design documentation “pdd” and Site preparation schedules, filing, follow-up, negotiation with Government enforcement authorities “Rostechnadzor”(RTN) to obtain positive conclusion necessary & required to 
                construct & build a float glass & coater production plant; obtain building permit from the authorizes, supervision of plant construction works; Hands on EHSS compliance supervision; elaboration; documentation & 
                data collection from contractor - sub-contractors & third parties consultants and negotiations with local & federal enforcement authorities in preparation for plant’s final inspection certification by regulatory 
                authorities; regulatory plant commissioning by enforcement & regulatory authorities, necessary to file and obtain the obligatory operation permit for plant and facility manufacturing process to start & to market 
                products, including license necessary for a classified facility to Operate, Store and Run a hazardous and chemical hazardous production objects. This procedure is specific to the Russian Federation.
            </p>

            <h3 className="label-sub-heading black">Project Administrator/project Engineer:</h3>
            <p className="label-text light">
                Ryazan (Russian Federation) Green Field project, Design, Build, Own, Operate (DBOO) and Transfer (T) of Guardian 1st. flat glass plant in the Russian Federation. Responsibilities includes but not limited to Land lease 
                Agreements (LLA), choice/negotiation of/with consultants & EPCM partner in the Russian the Federation.
                <br/><br/>
                Elaboration of project design documentation “pdd” based on local & international standards, norms, rules & regulations; file, negotiate & defend project design documentation “pdd” with federal competent enforcement  & 
                regulatory authorities “RTN” on Federal level to obtain positive conclusion necessary & required to obtain the Construction/Building Permit necessary & required for the construction of a float glass & coater production 
                plant, supervision of plant construction works, EHSS compliance supervision & “hands on”; elaboration/documentation collection & negotiations from/with contractor - sub-contractors & third parties consultants & 
                specialist companies as also local & federal regulatory enforcement authorities on RTN plant commissioning to obtain final inspection certificate, necessary to file and obtain the obligatory operation permit for 
                plant and facility manufacturing process; and the license necessary for a classified facility to Operate, Store and Run an hazardous and chemical hazardous production objects.
                <br/><br/>
                These included Project construction safety report (PCSR), Project operation safety report (POSR); Project Safety surveillance review (PSSR) for the Plant, supervision for final acceptance test & Act, and commissioning 
                of installation in the Russian Federation. Plant capacity: 750 t/d.
                <br/><br/>
                <strong>Project volume: 184 million €. Construction duration 14 months.</strong>
            </p>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    <strong>10/2014 – 04/2015 in parallel:</strong> Took ownership of Environmental compliance projects (EU-28 Industrial Emission Directive (IED) (2010/75/EU 28th. February 2012),  in the following Guardian facilities:
                </li>
                <li className="bullet-item">
                    <strong>Guardian Industries Navarra C.VS.L Spain:</strong> conceptualization, specification, design & procurement of flue gas cleaning systems & Continuous Emission Monitoring system (CEMS) comprising of Electrostatic 
                    precipitator “ESP” to abate Dust & heavy metals; Dry Scrubber for Sulphur Oxide - Sox abatement; Selective Catalytic Reduction “SCR” – secondary measure for  the abatement of Nitrogen Oxide, NOx. The objective of the 
                    project was to reach compliance with respect the EU Industrial Emission Directive 2010/75/EU. This included the design elaboration, based on BATs, filing and negotiating with the local regulatory enforcement authorities 
                    in Pamplona towards renewal of the exploitation permit which expired. The renewal of the operation permit was reached in March 2015, and the Air Pollution Control system (ESP- Scrubber – SCR – CEMS) is ongoing with completion 
                    due within 14 months i.e. by march 2016. Project volume 3.4 M€.
                </li>
                <li className="bullet-item">
                    <strong>Guardian Flachglas GmbH – Thalheim- Germany:</strong> specification, design & procurement of gas cleaning systems & Continuous Emission Monitoring system (CEMS); refurbishment & upgrade of Electrostatic precipitator 
                    “ESP” to abate Dust & heavy metals; Refurbishment of the Dry Scrubber - Sox abatement;. The objective of the project was to reach compliance with respect the EU Industrial Emission Directive 2010/75/EU for the Thalheim plant. 
                    This included the elaboration, design based on Best Available Technology “BAT”, filing and negotiating with the local regulatory enforcement authorities in  Sachsen Anhalt by installing a DeNOx system (Selective Catalytic 
                    Reduction “SCR” for  the abatement of Nitrogen Oxide NOx – secondary measure), which base on the EU directive should be operational in March 2016. Project volume 1.34 M€.
                </li>
                <li className="bullet-item">
                    <strong>Guardian Oroshaza KFT Hungary:</strong> Plant compliance enforcement, for the specification, design & procurement of gas cleaning systems & Continuous Emission Monitoring system (CEMS), comprising of Electrostatic 
                    precipitator “ESP” upgrade & refurbishment to abate Dust & heavy metals; renewal of the  Dry Scrubber system for Sulphur Oxide - Sox abatement; DeNOx system; Selective Catalytic Reduction “SCR” for  the abatement of Nitrogen 
                    Oxide NOx – secondary measure, to meet compliance on Nitrogen Oxide NOx Associated Emission Level with respect to industrial emission directive. Project scope due completion due within 14 months i.e. by March 2016. Project volume 2.9 M€.
                </li>
                <li className="bullet-item">
                    <strong>Guardian Llodio Uno, C.VS.L Spain:</strong> conceptualization, specification, design & procurement of complete turnkey package of flue gas cleaning systems & Continuous Emission Monitoring system (CEMS) comprising 
                    of Electrostatic precipitator “ESP”; Dry Scrubber for - Sox abatement; Selective Catalytic Reduction “SCR” for Nitrogen Oxide system. The objective of the project was to reach compliance with respect the EU Industrial Emission 
                    Directive 2010/75/EU. This included the elaboration, design based on  BATs, filing and negotiating with the local regulatory enforcement authorities in Bilbao towards renewal of the exploitation permit and to meet compliance 
                    for emission limits for nitrogen oxide (NOx), Sulphur oxide (Sox), dust (PM10), hydrogen chloride (HCL), hydrogen fluoride (HF) and heavy metals. Completion due within 14 months i.e. by March 2016. Project volume 3.4 M€.
                </li>
            </ul>
        </div>
        </>}

        { pageNo===2 &&
        <>
        <div className="team-profile-body-container">
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    <strong>International Project Management/Administration</strong> in over 13 (thirteen) countries within EU-28, CEE, North America, Middle East, Environmental & EHSS affairs coordinator, with the capacity of Cross functional Engineering 
                    support, addressing Technical Advisory issues & Services, executing and providing specialist advice on Engineering & project design related documentation, specifications, the design of Air pollution and/or flue gas cleaning plants & 
                    continuous emission monitoring systems, HVAC, international Project financing negotiations, Legal issues, applying Best Available techniques (BATs) according to EU Directives, or IFC & EBRD rules, regulations, norms and standards & guidelines.
                </li>
                <li className="bullet-item">
                    Engineering projects, troubleshooting projects, and providing support on all technical issues for designers, Engineers, End-Users/Customers, Architects, Consultants & suppliers contractors & sub - contractors. Bringing technical expertise 
                    from & across industrial & educational (training) sector background, in areas such as, Cement industry & detergent industry design, float glass industry, Sustainable environmental design issues, <strong>Environmental Health Social & 
                    Safety “EHSS”, Hazop</strong> and/or failure analysis, Basic & working project design documentation, , project management, manufacture/production & performance (Kaizen , lean production), Energy & Environmental site Audits, Waste Heat 
                    Recovery systems (RE) & Waste to Energy (WtE) systems , Air pollution and/or flue gas cleaning & Control systems, low C02 footprints plant design with respect to Kyoto mechanisms (JI & CDM), extensive knowledge and experience in EU Emission 
                    trading scheme (ETS), projects negotiations, erection, commissioning, start-ups, of divers industrial installations, Specialist knowledge also covers specific properties of glass use including Fire Fighting system design, acoustics noise 
                    abatement design & measuring Environmental Management & Auditing Systems, Quality & Environmental, Certification, in the capacity of Qualified Environmental Professional. Member Technical working Group “Glass for Europe "(Glass Alliance for Europe)"
                </li>
                <li className="bullet-item">
                    <strong>2000:</strong> Established a functional & result oriented EHSS dept. in Guardian Europe - CRVC in Dudelange, Grand Duchy of Luxembourg, responsible for all European, Central & Eastern Europe, Asia Minor & Middle East Manufacturing 
                    plants.
                </li>
                <li className="bullet-item">
                    <strong>2001 – 2003:</strong> Participation 2001 in the Coater project which later became “Guardian Luxcoating, ownership of the overall permitting & EHSS processes for the plant, obtaining the Operation permit in due time to start construction 
                    works with compliance to EU-28 directives, local rules, regulation and legislation. Assignments further included design and commissioning of the HVAC and the Sandblasting Installations for Coater process equipment’s.
                </li>
                <li className="bullet-item">
                    <strong>2003 – 06/2004:</strong> Guardian Luxguard I - Bascharage exploitation permit documentation process & elaboration for the Cold Tank repair. Designed & leadership of the exploitation permitting process according to local regulations and 
                    compliance requirements, including choice of accredited specialists and third-party Consultants for the necessary and required studies defining scope, terms of reference (ToR), & technical/procurement & negotiations with local suppliers & 
                    competent authorities.
                </li>
                <li className="bullet-item">
                    <strong>07/2004 – 2005:</strong> Technical/Expert support to Hunguard during Oroshaza- Hungary 2004/2005 during the Cold Tank Maintenance repair on Environmental & EHSS affairs for the  design & elaboration of the operation permit dossier, and 
                    flue gas cleaning system &/or Air Pollution Control systems (APCs), Electrostatic Precipitator to meet regulatory compliance according to local regulations & EU directives, including choice of accredited specialists and third party Consultants 
                    for the necessary and required studies and scope, terms of reference & relevant technical & commercial negotiations.
                </li>
                <li className="bullet-item">
                    <strong>2005 – 2006:</strong> Design of R290 (propane gas) HVAC Process cooling system for Guardian Automotive Plant in Luxembourg, with a total refrigerator cooling capacity of 1.6 MW, (comfort & industrial cooling) concept, choice of technology 
                    and refrigerant with respect to Ozone depletion & Global warming potentials, choice of accredited specialists and third party Consultants for the necessary and required scope of studies, terms of reference, technical/procurement negotiations, 
                    obtaining technical & regulatory concept approval from local competent authority to reach & be compliant with choice of cooling system for Guardian Automotive Europe “GAE”. The installation has been hitch-free since begin of operation in 2004.
                </li>
                <li className="bullet-item">
                    <strong>2003 – 07/2006:</strong> Parallel to the project in GAE, ownership of compliance and permitting projects & Environmental + EHSS affairs in Guardian plants in Llodio  Spain (float glass plant), Tudela Spain (float glass plant) & Valencia 
                    & Alicante Automotive Plants (Labradio & Choro Automotive parts plants), to ensure regulatory compliance  by elaborating, filing and obtaining operational permits and overall compliance including Handling and Storage of Hazardous & Chemical 
                    hazardous Preparations & Substances (including Paint Storage); VOC abatement systems and ad hoc training; upgrading firefighting systems, meet REACH requirements and stipulations, meet "Globally Harmonized System of Classification and Labelling 
                    of Chemicals (GHS)", Classification, Labeling & Packaging (CLP) requirements and compliance guidelines, of the above mentioned plants.
                </li>
                <li className="bullet-item">
                    Technical, Environmental, Health and Safety compliance support to GE Treasury during long - term senior loan negotiations with international Banks on Project financing, elaboration and technically explaining EIA Report studies to Bank and treasury 
                    staff, as also the development of Project summary description (PSD), non-technical summary of project (NTS), shareholder engagement plan (SEP); supervision & implementation of environmental social action plan (ESAP) & compliance reports; Terms of 
                    Reference (ToR) for the IPPC/IED, Best Available Techniques (BAT) Assessment based on stipulations when dealing with long-term senior loans from Banks;
                </li>
                <li className="bullet-item">
                    Elaboration of Project Requirements reports (PR) for senior loans in support to GE treasury by drafting PR, , implementation  supervision with EPCm Contractors, Sub Contractors, and preparing the ad hoc EHSS reporting for the Bank during 
                    construction phase.
                </li>
                <li className="bullet-item">
                    Review of Public Information Policy (PIP), so as to promote better awareness and understanding of its strategies, policies and operations towards interested & affected people where/if applicable.
                </li>
                <li className="bullet-item">
                    Project Complaint Mechanism (PCM) to provide an opportunity for an independent review of complaints from one or more individuals or from organizations concerning projects financed by the Bank which are alleged to have caused, or likely to cause, 
                    harm (to fauna & flora) etcetera.
                </li>
                <li className="bullet-item">
                    <strong>2013 – 04/2014:</strong> Conceptualized, designed based on Best Available Techniques (BATs) & made the choice & decision of accredited specialists and third party Suppliers & Consultants for the necessary and required scope of studies, 
                    terms of reference, technical/procurement negotiations, obtaining technical & regulatory concept approval from local competent authority to reach & be compliant on primary and secondary measures for flue gas cleaning systems (Emission reduction 
                    target for PM10, NOx, SOx, VOC, + …); Target percentage of GHG reduction (CO2, NOx; SOx, + …..); ROHS validation for all products (Restriction of hazardous substance); REACH Directive (Registration, evaluation, authorisation of Chemicals; CLP + 
                    GHS (classification, labelling & packaging) Targeting percentage of packaging material Reduction/recycled/disposal & percentage of recycled material per product launch; TSCA product validation if applicable (Toxic substances control Act); 
                    LCA(life cycle analysis),
                </li>
                <li className="bullet-item">
                    Energy Management + Saving initiatives & target %-tage of End of useful life disposal and/or recycling. Organized trainings and train personnel on flue gas cleaning & control systems concepts , technologies, & BATs for the industrial sector on 
                    corporate levels in Luxembourg, Tudela & Llodio (Spain), Oroshza (Hungary), Thalheim (Germany), Goole (UK), and Float glass Engineering (Detroit USA) & Kingsburg California to design conceptualize and reach compliance based on EU directive 
                    2010/75/EU on IPPC & the US Clean Air Act & be compliant with associated emission limit levels (AEL) as specified in the directive and legislations of the countries where we do business. These includes choice of accredited specialists and 
                    third-party Consultants for the necessary and required scope of studies, terms of reference, technical/procurement negotiations, obtaining technical & regulatory concept approval from local competent authority to reach & be compliant.
                </li>
            </ul>
        </div>
        </>}

        { pageNo===3 &&
        <>
        <div className="team-profile-body-container">
            <h3 className="label-sub-heading">Key Areas of performance/Areas of Experience & specialization includes but not limited to</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item check-list">
                    In-depth knowledge and experience in environmental & quality improvement programs, Environmental Health & Safety (EHS), certification processes for ISO 9000, ISO 14001, EMAS, 16001 and/or 50001 “Energy management system”: 
                    ISO 26000 “(enhancement of consumer/producer relationship)
                </li>
                <li className="bullet-item check-list">
                    Elaboration, development, conceptualization, co-ordination & supervision of Environmental Impact Assessment (EIA) studies, Environmental social due diligence (ESDD) (brown field/green field, hydro carbon (oil/fuel mix) storage 
                    concepts, BATs Assessment, Internal/External Audits + Environmental Site Audits (ESA), coordination for soil investigations (geodesic & topographical surveys), follow up & surveillance on international, EU directives, legislation, 
                    regulation norms and Standards, communication with local competent authorities, third parties, Government Ministries & administrative agencies, elaboration of “technical tasks”/ “technical conditions” for scope of work/ToR 
                    execution as part of project management assignment.
                </li>
            </ul>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Compliance procedure in chemical hazardous production object/material handling certification/licensing, risk analysis.
                </li>
                <li className="bullet-item">
                    Consulting & Co-coordinating pollution control, prevention & elimination, waste management concepts (3R : Reduce-Recycle-Reuse) studies, renewable energy, cogeneration and waste heat recovery program studies, Waste to Energy (WtE), 
                    trouble-shooting air/water/soil pollution control systems constraints and continuous emissions monitoring (CEMS); & abatement equipment
                </li>
                <li className="bullet-item">
                    Managing and providing industrial engineering best practices at facility-level on environmental projects; Researching and providing insight and analysis for local & international regulations, guidance, and policy
                </li>
                <li className="bullet-item">
                    Conducting internal compliance audits; training entry-level (and beyond) personnel on plant-level regulatory compliance activities and corporate environmental procedures and policies CSR
                </li>
                <li className="bullet-item">
                    Specification, coordination, supervision and obtaining approvals and conclusions for project design documentation, detail design, working design & as built documentation drawings, construction management (EPCM) and running of PMO; 
                    interacting, coordination, supervision and putting corrective measures in place towards obtaining all relevant permits, conclusions, and Acts from/with local competent authority construction supervision control organs/agencies, in 
                    all project specific areas as may be required by international/local legislation, rules regulations, norms, directive, and standards
                </li>
                <li className="bullet-item">
                    In-depth, extensive and proven empiric knowledge & experience in the Conceptualization, specification, design & scope negotiation, procurement, construction and/or installation, commissioning and start-up of industrial & manufacturing 
                    equipment & installations, flue gas cleaning systems “standalone abatement systems and installations. Hands-on experience with air pollution control systems, continuous emission monitoring system, (scrubbers, electrostatic precipitators, Selective Catalytic Reduction “SCR”, etc.
                </li>
                <li className="bullet-item">
                    Extensive, consolidated and conclusive knowledge and know-how on Best Available techniques reference document (BAT ref doc) Glass sector and associated areas, Integrated pollution prevention and control “IPPC” directive on Industrial 
                    emission, permitting & compliance process, with specialized knowledge & experience applicable to CEE (Central and Eastern Europe), Africa , & the Russian Federation, the EU
                </li>
                <li className="bullet-item">
                    Multi-task able and experienced in a wide range of Engineering disciplines and areas such as, HVAC Cooling Tower systems & applications, Water treatment facilities, Sewage discharge concepts and constraints, Storm water treatment & Rain water collection installations, (Water/Waste water treatment installation; Rain water collection/storage and filtration facilities). Compressed Air system design. Gas equipment and gas consumer design, installation, testing, reception, permitting and commissioning. Mixed Fuel usage “LFO/HFO” design, storage and handling acc. to international & local norms, rules & regulation, process gas design, installation and commissioning equipment choice/procurement, installation, supervision and commissioning & Start-up of technical utilities; Fire protection/Firefighting systems.
                </li>
                <li className="bullet-item">
                    Representing Guardian Europe in several international and national Environmental & industrial technical working groups, attending & organizing meetings, Workshops, seminars and conferences (Federation of Luxembourg industries “Fédil”; 
                    Glass for Europe/Glass Alliance for Europe, Technical working group Integrated pollution prevention control 2001 review & revision committee resulting in the Commission Implementing Decision of 28 February 2012 establishing the best available techniques (BAT) conclusions 
                    under Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions for the manufacture of glass
                </li>
                <li className="bullet-item">
                    Strong and proven work ethic and integrity, Assertive, Hands On, detail-oriented with excellent written and oral knowledge & communication skills; demonstrated leadership skills; experience building relationships with diverse groups of 
                    people at all levels of an organization; Over 35 years of Proven/tested experienced Engineering background, Environmental policy & environmental compliance experience in divers industrial settings; ability to work independently, with little supervision as also a team player; 
                    demonstrated ability to influence others (governments, technical working groups, government administrations and agencies, third parties via strong relationships & subject matter knowledge, analytic skills; proactive and positive thinking
                </li>
            </ul>

            <h3 className="label-sub-heading">Further Job-related Experience includes</h3>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">1992/2001</div>
                    <div className="end-date">2002</div>
                </div>
                <p className="label-text timeline">
                    Training as Quality Circle facilitator, Kaizen, Kanban; 2001; EMAS & ISO 14001; facilitator; internal/external auditor, Energy Audits, principles of legislation, norms, rules, regulations; relationship Auditor/and Techniques of communication and auditing reporting; OHSAS 18001 facilitator (Occupational Health and Safety Certification system certified), Environmental Health & Safety “EHS”.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">1996</div>
                    <div className="end-date">05/2000</div>
                </div>
                <p className="label-text timeline">
                    Technical Manager/ Plant Manager Chemolux S.a.r.l (A Henkel Subsidiary in Foetz-Luxembourg), responsible for the day to day running of the plant.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">1985</div>
                    <div className="end-date">12/1995</div>
                </div>
                <p className="label-text timeline">
                    S.A. des Ciments Luxembourgeoise. Maintenance and New project Engineer, procurement & procurement negotiations, coordination and organization of work 
                    procedures, regulatory periodic control and verification of installations and legislative compliance by third parties/local competent authorities, 
                    compliance control with respect to EU-28 directives and local rules, regulations, norms and standards, “EHS” Safety, Health & Environmental affairs responsible.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">01/1984</div>
                    <div className="end-date">12/1984</div>
                </div>
                <p className="label-text timeline">
                    Mold Plant Tire Designer Good Year International Technical Center Colmar Berg Luxembourg.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">01/1985</div>
                    <div className="end-date">04/1985</div>
                </div>
                <p className="label-text timeline">
                    Interpublicité Luxembourg: Designer; preparation, elaboration & development of technical Design & datasheets (brochures) for Hydraulic pumps catalogues & pamphlets
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">01/1982</div>
                    <div className="end-date">12/1983</div>
                </div>
                <p className="label-text timeline">
                    Lecturer II, Anambra State University of Technology Nigeria - Dept. of Mechanical Engineering.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">03/1980</div>
                    <div className="end-date">12/1981</div>
                </div>
                <p className="label-text timeline">
                    Ingenieurburo Imobau und Klompken (Offenbach a.m.) Designer piping systems for nuclear plants.
                </p>
            </div>

            <h3 className="label-sub-heading">Education & Pre MBA-Experience</h3>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">11/1991</div>
                    <div className="end-date">06/1994</div>
                </div>
                <p className="label-text timeline">
                    MBA Sacred Heart University Fairfield, Connecticut Luxembourg Campus. Specialization in Production and operations management, Managerial economics & accounting, Macroeconomics & Organizational behavior
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">10/1975</div>
                    <div className="end-date">02/1980</div>
                </div>
                <p className="label-text timeline">
                    University of Applied Sciences Frankfurt am Main, Mechanical Engineering; Dipl. Ing.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">09/1974</div>
                    <div className="end-date">09/1975</div>
                </div>
                <p className="label-text timeline">
                    Fachoberschule der Erasmus Kittler Schule Darmstadt – Fachabitur.
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">10/1971</div>
                    <div className="end-date">08/1974</div>
                </div>
                <p className="label-text timeline">
                    Kreisberufsschule Michelstadt in Odenwald Germany; Practical & theoretical apprenticeship Training in automobile mechanics/electric and body works; “GessellenBrief” (German equivalent of City & guilds certificate or the French /Luxembourg CATP)
                </p>
            </div>
            <div className="expirence-container education">
                <div className="date-container timeline">
                    <div className="start-date">01/1984</div>
                    <div className="end-date">12/1984</div>
                </div>
                <p className="label-text timeline">
                    Primary and secondary education in Laduma/Mpundu Balong – Muyuka district; United Republic of Cameroon
                </p>
            </div>

            <div className="mark-sheet-cont">
                <div className="mark-sheet-desc">
                    <h3 className="label-sub-heading">Personal Languages Skills</h3>
                    <p className="label-text">
                        A1/2: basic user = 25 %<br/>
                        B1/2: independent user = 50 %<br/>
                        C1/2: proficient user = 100 %<br/>
                        Common European Framework of Reference for Languages
                    </p>
                </div>
                <img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/markSheet-philip.png" alt="" className="mark-sheet-img"/>
            </div>

            <h3 className="label-sub-heading">Communication Skills:</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Communication skills gained through experience as plant consultant and Environmental & Government Affairs, regulatory & compliance policy officer interacting with VPs, directors and management, line workers, (rank & file), 
                    public institutions, government ministries, agencies , administrations as also compliance enforcement and regulatory officials and agencies, accredited third party auditors, verifiers and specialist consultants.
                </li>
                <li className="bullet-item">
                    Public speaking skills gained through a series of speeches, lectures & conferences, seminars, workshops, symposiums and lessons given (participated) during seminars and workshops as speaker, moderator and/or facilitator
                </li>
            </ul>

            <h3 className="label-sub-heading">Organizational/managerial skills</h3>
            <ul className="label-bullet-text light">
                <li className="bullet-item">
                    Planning , managing projects for different clients, budget, milestones.
                </li>
                <li className="bullet-item">
                    Project management and team management (with 15 different languages spoken on Site)
                </li>
                <li className="bullet-item">
                    Rigorous approach to ensure high quality delivery to internal/external clients
                </li>
                <li className="bullet-item">
                    Goal/Mission oriented and mission driven “ result achievement in due schedule”
                </li>
                <li className="bullet-item">
                    Proactive and hands on approach
                </li>
                <li className="bullet-item">
                    Managing complex issues involving multiple stakeholders – problem solving
                </li>
                <li className="bullet-item">
                    Output delivery even on very tight schedules, capacity to respect deadlines (pre-requisite is a good & functional team”
                </li>
                <li className="bullet-item">
                    Supervision and quality control of staff/team delivery
                </li>
            </ul>

            <h3 className="label-sub-heading">Computer skills:</h3>
            <p className="label-text light">
                <strong>Computer literate (Word, Excel, PowerPoint, MS project, outlook, others)</strong>
            </p>

            <h3 className="label-sub-heading">Hobby:</h3>
            <p className="label-text light">
                Reading Creative books, Philosophy, History, Political, Science, Puzzling on LDC/emerging economies country challenges, Kiwanis Service Club Member (Esch – Sur Alzette Branch; Grand Duchy of Luxembourg)
            </p>
        </div>
        <div className="footer-banner blue">
            <p className="banner-text">
                Long term goals, visions, aims and objectives: to stand on the shoulders of giants and to see beyond my horizon still, and to recommend myself for more challenging opportunities to serve humanity and my continent of origin. Community Activities: Member of Uli progressive Union/support & help in education, basic healthcare towards less privileged in my homeland community in Amamputu-Uli Anambra State Nigeria & our Society.
            </p>
        </div>
        </>}

        <div className="bottom-btn-container">
            <button className="navigate-button" onClick={handleBack}>Back</button>
            <div className="pages-button-cont">
                <Link to={'/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=1'} className={`page-btn ${pageNo===1 && 'active-btn'}`}>1</Link>
                <Link to={'/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=2'} className={`page-btn ${pageNo===2 && 'active-btn'}`}>2</Link>
                <Link to={'/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=3'} className={`page-btn ${pageNo===3 && 'active-btn'}`}>3</Link>
            </div>
            <button className="navigate-button" onClick={handleNext}>Next</button>
        </div>
        
        <div className="footer-banner">
            <p className="banner-text">Veerasamy Lane, Lapeyrousse Street, Eau-Coulee Curepipe Republic of Mauritius</p>
            <a href="tel: (230) 698 1045" className="banner-text">Tel.-(230) 698 1045</a>
            <a href="mailTo: ghirishgopal@gmail.com" className="banner-text padding-bt-2">ghirishgopal@gmail.com</a>
            <div className="download-profile" onClick={handleDownloadProflie}>
                <img loading="lazy" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/download-icon.png" alt="" className="download-icon" />
                <p className="download-text">Download Profile</p>
            </div>
        </div>
        </div>
    )
}

export default PhilipUzuma