import React, { useEffect } from "react";
import { Button } from "antd";
import { HomeFilled } from '@ant-design/icons'
import { Link } from "react-router-dom";
import CheckedIcon from '../checked-icon.svg'

const ThankYou = () => {

    useEffect(() => {
        scorllToTop()
    }, [])
    
    const scorllToTop = () => {
      window.scrollTo({
          top : 0,
          behavior : "smooth"
      })
    }

    return (
        <>
        <div className="not-found-container">
            <img src="https://fica-data.s3.ap-south-1.amazonaws.com/img/thankyou-image.png" className="thankyou-bg left" alt=""/>
            <img src="https://fica-data.s3.ap-south-1.amazonaws.com/img/thankyou-image.png" className="thankyou-bg right" alt=""/>
            <img src={CheckedIcon} alt="" className="not-found-img thankyou" />
            <h3 className="not-found-heading thankyou" style={{color:'#0C0C0C'}}>Thank You for Reaching Out!</h3>
            <p className="not-found-text thankyou">
                Your message is important to us, and we appreciate you taking the time to connect. We're excited to assist you and will get back to you shortly
            </p>
            <div className="go-to-home">
                <Link to={'/'} className="not-found-link">
                    <Button icon={<HomeFilled style={{color:'#D59847'}}/>} className="home-button">
                        Back To Home
                    </Button>
                </Link>
            </div>
        </div>
        </>
    )
}

export default ThankYou