import React, { useEffect } from 'react';
import { goals } from '../constants';

function OurGoal() {

  useEffect(() => {
    scorllToTop()
  }, [])

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  return (
    <div className='goal-container'>
      <img loading='lazy' className="goal-logo" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/Goals.png" alt="Logo" />
      <div className='goal-content'>
        <h2 className='goal-title'>Our Goals</h2>
        <p>
          Promoting investment in a good way by driving the opportunities to the needs.
          <br/>
          The platform will bring transparency and bankable investment projects without any political interference. Mostly to promote:
        </p>
        <div className='goal-grid'>
          {goals.map((goal) => (
            <div key={goal.id} className='goal-item'>
              {goal.title}
            </div>
          ))}
        </div>
        <p>FICA's investment good deals strategy aimed to attract more investors and partners among African countries for the successful project's implementation of the project. Our network and knowledge platform under the FICA leadership could generate a combined consumer and business spending of trillion by 2030</p>
        <p>Furthermore, markets and economies across the Continent will be reshaped, leading to the creation of new industries and the expansion of key sectors. Significantly, it would make African countries more competitive and attractive globally.</p>
        <p>Strategic cooperation with strong States developing partners will offer an opportunity to promote good governance both globally and across Africa, through the concept of “investment Integrity” defined as international tools that are legitimate, transparent and properly addressed as a way to ensure the legitimacy of the global investment system.</p>
        <p>The prevalence of corruption-procured or produced on goods and services, invoiced investment compliance.</p>
        <p>The benefits of cooperation will set an example for the world</p>
        <p>Across the world, countries are questioning investment agreements and economic integration, alongside turning away from global cooperation, leadership and collective action. Political dynamics are driving short-termism, polarization and isolationism</p>
        <p>Yet our multiple threats demand long-term thinking and greater cooperation and this is precisely what the FICA represent.</p>
        <p>While the world turns in one direction, FICA will move in the other by deepening ties across the continent.</p>
      </div>
    </div>
  )
}

export default OurGoal;
