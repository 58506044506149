import React from 'react';
import { Link } from 'react-router-dom';

function OverView() {
  return (
    <section className="banner" style={{backgroundImage: 'url(https://fica-data.s3.ap-south-1.amazonaws.com/img/banner.jpeg)'}}>
      <div className="banner-container">
        <p className='hero-title'>(F.I.C.A.)</p>
        <p className="hero-content">FICA is an International Organization working to preserve Humanity, climate change, food security, health and welfare, capacity building in technical training, and disclosure of economic ecosystems.</p>
        <p className="hero-content">Environmental protection, and development of inclusive & sustainable infrastructure & we therefore put our respective skills to work for economic and social development in Africa.</p>
        <div className='banner-button'>
          <Link to='/about-fica' >
            <button className="hero-button">Read More</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default OverView;
