import React, { useEffect, useState } from 'react';
import { partners } from '../../constants';

function Partners(props) {
    const [activeTab, setActiveTab] = useState('Our Partners');

    useEffect(() => {
        // console.log(props)
        if(props.activeTab==='ourpartners') {
            setActiveTab('Our Partners')
        }
        if(props.activeTab==='otherPartner') {
            setActiveTab("Other's Partners")
        }
        if(props.activeTab==='networkingCountry') {
            setActiveTab('Networking Countries')
        }
    }, [props.activeTab])

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const activeImages = partners.find(partner => partner.title === activeTab)?.images || [];

    return (
        <>  
            <div className="tab-container">
                {partners.map(partner => (
                    <h3
                        key={partner.title}
                        className={`tab-item ${activeTab === partner.title ? 'active' : ''}`}
                        onClick={() => handleTabClick(partner.title)}>
                        {partner.title}
                    </h3>
                ))}
            </div>
            <div className={`profile-img-container ${activeTab == "Networking Countries" ? 'networkingcountries' : ''}`}>
                {activeImages.map(image => (
                    <div key={image.id}>
                    <img loading='lazy' src={`${image.image}`} alt={`Partner ${image.id}`} />
                    {activeTab==='Networking Countries' && image?.imageTitle && <p className='image-title'>{image?.imageTitle}</p>}
                    </div>
                ))}
            </div>
        </>
    );
}

export default Partners;
