import React from 'react'

function Engagment({instituional}) {
  return (
    <div className='padding-4'>
        <p>
          We, the Citizens of the World, people from different backgrounds working for progress, wherever the need
          is felt across the globe, are committed to respecting the human dignity & life of all peoples. We at FICA
          undertake and commit to being socially responsible.
        </p>
        <p>
          We are committed to contributing impartially, to the economic development of each country in general
          and African countries in particular. Our actions are oriented towards economic endeavors, for the
          emergence of the African continent and the equitable sharing of wealth. We contribute through our
          accompanying actions to emerge in different socioeconomic areas, projects with real impact on
          regional development for improving the living conditions of the African population.
        </p>
        <p>
          We are committed to promoting good governance at all levels of African societies. We are committed to supporting men & women sharing the concept of shared value, able to place the general interest of our societies, at the heart of any commitment.
        </p>
        <p>
          We operate in good faith and character. We commit and engage ourselves to put forward all actions and synergies for collective work and benefit, forging shared and equitable economic externalities, without abuses against the oppressed and/or less privileged of our societies. We assume the role of facilitator and spokes-person for the disabled, people with reduced mobility, the orphan, the afflicted woman, dehydrated child, and the youth, the aged and the war thorn.
        </p>
        {instituional && <img loading='lazy' style={{maxWidth: '100%'}} src='https://fica-data.s3.ap-south-1.amazonaws.com/img/about/institutional/engagement.png' alt='engagment' />}
        {!instituional && <img loading='lazy' style={{maxWidth: '100%'}} src='https://fica-data.s3.ap-south-1.amazonaws.com/img/about/engagement.png' alt='engagment' />}
    </div>
  )
}

export default Engagment