import React from 'react';
import { Link } from 'react-router-dom';
import { goals } from '../../constants';

function AimsGoals() {
  return (
    <>
      <div id="aim" className='aimsgoals-container'>
        <img loading='lazy' className="aimsgoals-logo" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/Aims.png" alt="Logo" />
        <div className='aimsgoals-content'>
          <h2 className='aimsgoals-title'>Our Aim</h2>
          <p>
            The platform aims to connect governments with investors who are interested in investing in Africa.
          </p>
          <p style={{paddingTop: '30px'}}>
            On this platform, we also provide value-add service providers offering services such as:
          </p>
          <ul>
            <li>Packaging</li>
            <li>Logistic Transportation</li>
            <li>Warehousing</li>
            <li>Financing</li>
            <li>Cross-border compliance</li>
            <li>Etc.</li>
          </ul>
        </div>
      </div>
      <div id="goals" className='aimsgoals-container goals'>
        <h2 className='aimsgoals-title goals'> Our Goals </h2>
        <p> Promoting investment in a good way by driving the opportunities to the needs. </p>
        <p> The platform will bring transparency and bankable investment projects without any political interference. Mostly to promote: </p>
        <div className="goals-content">
          <img loading='lazy' className="goals-logo" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/Goals.png" alt="Logo" />
          <div className="tables-container">
            <div className='aimsgoals-grid'>
              {goals.map((goal) => (
                <div key={goal.id} className='aimsgoals-item'>
                  {goal.title}
                </div>
              ))}
            </div>
              <Link style={{textDecoration: 'none', color: 'white'}} to="/goals" className="aimsgoals-button">Read More</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default AimsGoals;
