import React from "react";
import { Divider } from "antd";

const DailyRamasia = () => {

    const handleDownloadProflie = () => {
        const link = document.createElement('a')
        link.href = `https://fica-data.s3.ap-south-1.amazonaws.com/pdfs/Daily-Ramasia.pdf`
        link.download = 'Daily-Ramasia.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div id="daily-ramasia-page-download">
        <div className="team-profile-hero-container daily-ramasia">
            <div className="left-intro">
                <div className="cirlce-bg">
                    <div className="hero-image">
                        <img src={`https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/dailyRamasia.jpeg`} alt="" className="team-hero-img daily-ramasia" style={{top:'0%', left:'-10%', height:'450px'}}/>
                    </div>
                </div>
                <h2 className="title">Daily Ramasia</h2>
                <h3 className="designation" style={{margin:'1rem 0'}}>Vice President (VP)</h3>
                <h4 style={{margin:'0', fontSize:'1.25rem', fontWeight:'600'}}>Born on 14/04/1977</h4>
            </div>
            <div className="right-intro">
                <p className="intro-text"><span className="brown">BAH:</span> Founder l Chair l CEO</p>
                <p className="intro-text"><span className="brown">LED Foundation:</span> Founder and Chair</p>
                <p className="intro-text"><span className="brown">PHLANET Lithium (PVT) Pty:</span> Co-Founder l Director</p>
                <p className="intro-text"><span className="brown">FICA International Federation of Consulate Chambers:</span> Sub-Saharan Africa Vice President (VP)</p>
                <Divider style={{borderBottom:'1px solid #000'}}/>
                <p className="intro-text"><span className="brown">Qualifications</span></p>
                <p className="intro-text">Executive MBA (Henley Business School)</p>
                <p className="intro-text">International ESG Certificate (TBRA)</p>
                <p className="intro-text">People Management Programe Certificate (GIBS)</p>
                <p className="intro-text">Financial Management for Non-Financial Managers Certificate (GIBS)</p>
                <p className="intro-text">Human Resources Degree (UNISA)</p>
                <p className="intro-text">Currently: Preparing for DBA registration in International Business</p>
                <p className="intro-text"><span className="brown">Professional Registration:</span> IoDSA member</p>
            </div>
        </div>
        <div className="team-profile-body-container">
            <h3 className="label-heading">Biography-</h3>
            <p className="label-text light">
                Daily is a renowned business and professional woman. Passionate about African economic growth that embrace the ethos of ESG. She an excellent investor 
                to business leader connector, because of her strategic abilities to see potential and growth in various sectors that contributes to the global economic value chain. 
                Her strategic business capability acumen and firm believer of ESG and sustainability, she envisions to contribute in building the legacy of the next generations to 
                come. Daily has managed to identify bankable business projects in South Africa, Zimbabwe, Namibia, Lesotho, and DRC. These bankable business projects require an average 
                50mil USD to 2.5bil USD investment, and have an average life span of about 30 – 100 years of business life cycle. This business projects are in the following economic 
                private sectors: mining, oil and gas, renewable green energy, agriculture, and infrastructure (Mega-Cities, Mall Construction, etc.).
                <br/><br/>
                The above was capabilities were made it possible through her 23 years of working for multinational corporate private and state owned organizations, that enabled her to 
                flourish in her professional and business career journey. She worked at the following organizations: Eskom (Energy), Anglo American (Mining), Pension Funds (Governance, 
                Investment and ESG), Airports Company SA (Aviation), Alex Forbes (Insurance – Investment – Risk Insurance), Nedbank (Financials), and ICT. 
                <br/><br/>
                Her current 18 years of Executive and being a Board member, has widen her purpose led vision to become a corporate entrepreneur business woman, by putting purpose at 
                the center of everything she does. Her ability to effectively engage and lead effective stakeholder engagements, have built a strong rapport and networks in various sectors.
            </p>

            <h3 className="label-heading">Current Positions and Roles-</h3>
            <ul className="label-bullet-text">
                <li className="bullet-item">
                    <strong>Founder and Chair of Bani Altharwa Holding (BAH)</strong><br/>
                    Established to be the world leading wealth builder through participating in the primary and tertiary economy, 
                    investments and logistics solutions. BAH is in partnership with FICA International Federation of Consulate 
                    Chambers for Africa which focus on Economy Diplomacy.
                </li>
                <li className="bullet-item">
                    <strong>Chair and Co-founder and of LED foundation</strong><br/>
                    Aimed at developing and advising the youth development, with the strong focus of bringing the world of 
                    work to learners/ student and drive the importance of 4/5IR future skills and skills of the future.
                </li>
                <li className="bullet-item">
                    <strong>Non-executive Board member</strong><br/>
                    Health Professional Council of South Africa for to ensure good governance in line relevant 
                    regulatory legislation framework, global best practice in line with King IV Code of Good Practice.
                </li>
                <li className="bullet-item">
                    <strong>Chairperson for Centurion Christian School</strong><br/>
                    Ensure good governance in line with the global and South African school development.
                </li>
            </ul>

            <h3 className="label-heading">Value Add Areas Of Expertise-</h3>
            <p className="label-text light">
                Daily has worked for the following sectors, namely, Energy, Mining, Investment and Insurance, Pension Funds, 
                Aviation, Banking, and is currently going to join the ICT business as Group Executive of Group. Her renowned 
                unique capabilities that ranges from strategy scenario planning, strategy choice and development, change 
                management, organizational culture, human capital, ESG and sustainability, financial management (CAPEX, CAPEX, 
                International Debt Financing) and tax, remuneration governance, statutory reporting enabled her to be a 
                career trajectory.
            </p>

            <h3 className="label-heading">Academic Achievements-</h3>
            <ul className="label-bullet-text">
                <li className="bullet-item">
                    <strong>ESG Executive Certification: The Boardroom Africa (TBRA) - 2023</strong>
                </li>
                <li className="bullet-item">
                    <strong>Masters: Business Administration (MBA) Henley Business School - 2022</strong>
                </li>
                <li className="bullet-item">
                    <strong>Certificate: Financial Management for Non-Financial Manager - GIBS - 2019</strong>
                </li>
                <li className="bullet-item">
                    <strong>Certificate: Programmer for Management Excellence GIBS - 2012</strong>
                </li>
                <li className="bullet-item">
                    <strong>Bachelor of Technology: Human Resources Management UNISA - 2006</strong>
                </li>
                <li className="bullet-item">
                    <strong>National Diploma: Human Resources Management Tshwane University of Technology, South Africa – 2001</strong>
                </li>
            </ul>

            <h3 className="label-heading">Professional Memberships-</h3>
            <ul className="label-bullet-text light-weight">
                <li className="bullet-item">
                    Institute of Directors in South Africa (IoDSA) member, Master Reward Specialist and Chartered HR Professional.
                </li>
                <li className="bullet-item">
                    An independent Principal Executive Officer registered with the Financial Sector Conduct Authority.
                </li>
            </ul>

            <h3 className="label-heading">Hobbies-</h3>
            <ul className="label-bullet-text light-weight">
                <li className="bullet-item">
                    Reading and journaling, Hiking, Spirituality and meditation, Music, Movies.
                </li>
                <li className="bullet-item">
                    Travelling and sightseeing and house decoration.
                </li>
            </ul>
        </div>
        <div className="footer-banner">
            <p className="banner-text">Rustenburg, Northwest Province South Africa. Current address: 2896 Falcon Street, Centurion, Gauteng Province, South Africa, 0157.</p>
            <a href="tel: (+27) 83 391 9073" className="banner-text">Tel. (+27) 83 391 9073</a>
            <a href="mailTo: daily.ramadia@gmail.com" className="banner-text padding-bt-2">Email: daily.ramadia@gmail.com</a>
            <div className="download-profile" onClick={handleDownloadProflie}>
                <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/download-icon.png" alt="" className="download-icon" />
                <p className="download-text">Download Profile</p>
            </div>
        </div>
        </div>
    )
}

export default DailyRamasia