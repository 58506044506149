import React, { useEffect } from "react";
import { Button } from "antd";
import { HomeFilled } from '@ant-design/icons'
import { Link } from "react-router-dom";

const NotFound = () => {

    useEffect(() => {
        scorllToTop()
    }, [])
    
    const scorllToTop = () => {
      window.scrollTo({
          top : 0,
          behavior : "smooth"
      })
    }

    return (
        <>
        <div className="not-found-container">
            <img loading='lazy' src="https://fica-data.s3.ap-south-1.amazonaws.com/img/404.png" alt="" className="not-found-img" />
            <h3 className="not-found-heading">OOPS!</h3>
            <p className="not-found-text">
                The page you are looking for does not exist. It might have moved or delete.
            </p>
            <div className="go-to-home">
                <Link to={'/'} className="not-found-link">
                    <Button icon={<HomeFilled style={{color:'#D59847'}}/>} className="home-button">
                        Back To Home
                    </Button>
                </Link>
            </div>
        </div>
        </>
    )
}

export default NotFound