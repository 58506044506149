import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import {OverView, AimsGoals, Insights, Profiles,Parnters} from '../components';
import {teamProfile, boardMembers} from '../constants'

function LandingPage() {

  const location = useLocation()
  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    if(location.state && location.state.scrollVal) {
      if(location.state.activeTab) {
        setActiveTab(location.state.activeTab)
      }
      scrollToSection(location.state.scrollVal);
    } else {
      scorllToTop()
    }
  }, [location.state])

  const scrollToSection = (id) => {
    const element = document.getElementById(id)
    if (element) {
      const offset = 200
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      })

      setTimeout(() => {
        if (location.state && location.state.scrollVal === id) {
          window.history.replaceState(null, '')
        }
      }, 1000)
    }
  };

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  return (
    <div>
      <div id='overview' ><OverView /></div>
      <div><AimsGoals /></div>
      <div><Insights /></div>
      <div id='team-profile' ><Profiles title='Team Profile F.I.C.A.' data={teamProfile} /></div>
      <div id='board-team-member' ><Profiles title='Board Team Members F.I.C.A.' data={boardMembers}/></div>
      <div id='ourpartners' ><Parnters activeTab={activeTab} /></div>
    </div>
  )
}

export default LandingPage