import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { PlusOutlined } from '@ant-design/icons'
import { goals, serviceProvider, teamProfileFICA, insights, aboutFICA, footerParnter, netWorkingCountry, ficaSolutions, boardProfileFICA, partners } from '../constants';

function Footer() {

  const navigate = useNavigate()

  const [ourGoals, setOurGoals] = useState(false)
  const [service, setService] = useState(false)
  const [teamProfile, setTeamProfile] = useState(false)
  const [boardProfile, setBoardProfile] = useState(false)
  const [insightsFlag, setInsightsFlag] = useState(false)
  const [solution, setSolution] = useState(false)
  const [network, setNetwork] = useState(false)
  const [about, setAbout] = useState(false)
  const [partner, setPartner] = useState(false)

  const handleNavigate = (url) => {
    navigate(url)
  }

  const handleNavigateWithState = (url, state) => {
    navigate(url, {state: state})
  }

  return (
    <>
      <div className='helper-container'>
        <Link to="/help" className='helper-text'> We are here to help</Link>
      </div>
      <div className='footer-about'>
        <img loading='lazy' src='https://fica-data.s3.ap-south-1.amazonaws.com/img/footer-logo.png' alt='' className='footer-logo' onClick={()=>handleNavigate('/')} />
        <div>
          <span className='footer-about-text'>Registration Number 001/FICA/SG/PDTE/2017 its Registered Office at BP 286 s/c Ministry of Economy  Ndjamena-Republic of Chad.</span>
          <br/><br/>
          <span className='footer-about-text'>Development Office: Bole Sub City Woreda 03 House 2759 Po Box 1426 Addis-Ababa Federal Democratic Republic of Ethiopia.</span>
        </div>
      </div>
      <div className='footer-container'>
        <div className='footer-col row'>
          <p onClick={()=>{navigate('/',{state:{scrollVal:'aim'}})}} className='footer-link font-1rem'>Our Aim</p>
          <Link to={'/goals'} className='footer-link font-1rem'>Our Goals</Link>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>About F.I.C.A. <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${service ? '45deg' : '0deg'}`}} onClick={()=>setService(!service)} /></div>
            <div className={`unhide-drop-down ${!service && 'footer-col-expand'}`}>
              {aboutFICA.map((link) => (
                  <div key={link.id}>
                  <span className='footer-link' onClick={()=>handleNavigate(link.redirect)}>{link.title}</span>
                  </div>
              ))}
            </div>
          </div>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>Team Profile F.I.C.A. <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${teamProfile ? '45deg' : '0deg'}`}} onClick={()=>setTeamProfile(!teamProfile)} /></div>
            <div className={`unhide-drop-down ${!teamProfile && 'footer-col-expand'}`}>
              {teamProfileFICA.map((link) => (
                <div key={link.id}>
                  <a href={link.redirect} className='footer-link' >{link.title}</a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>Board Team F.I.C.A. <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${boardProfile ? '45deg' : '0deg'}`}} onClick={()=>setBoardProfile(!boardProfile)} /></div>
            <div className={`unhide-drop-down ${!boardProfile && 'footer-col-expand'}`}>
              {boardProfileFICA.map((link) => (
                <div key={link.id}>
                  <a href={link.redirect} className='footer-link' >{link.title}</a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>Insights <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${insightsFlag ? '45deg' : '0deg'}`}} onClick={()=>setInsightsFlag(!insightsFlag)} /></div>
            <div className={`unhide-drop-down ${!insightsFlag && 'footer-col-expand'}`}>
              {insights.map((link) => (
                <div key={link.id}>
                  <span className='footer-link' onClick={()=>handleNavigate(link.redirect)}>{link.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>F.I.C.A. Solution <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${solution ? '45deg' : '0deg'}`}} onClick={()=>setSolution(!solution)} /></div>
            <div className={`unhide-drop-down ${!solution && 'footer-col-expand'}`}>
              {ficaSolutions.map((link) => (
                <div key={link.id}>
                  <span className='footer-link' onClick={()=>handleNavigateWithState(link.redirect, link.navState)}>{link.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='footer-col'>
          <div className='footer-col-content'>
            <div className='footer-col-heading'>Partners <PlusOutlined className='footer-plus-icon' style={{color:'#FFF', rotate:`${partner ? '45deg' : '0deg'}`}} onClick={()=>setPartner(!partner)} /></div>
            <div className={`unhide-drop-down ${!partner && 'footer-col-expand'}`}>
              {footerParnter.map((link) => (
                <div key={link.id}>
                  <span className='footer-link' onClick={()=>handleNavigateWithState(link.redirect, link.navState)}>{link.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
      <div className="footer-bottom-container">
        <div className="footer-icons">
            <a href='#'><FaFacebook /></a>
            <a href='#'><FaTwitter /></a>
            <a href='#'><FaLinkedin /></a>
            <a href='#'><FaInstagram /></a>
            <a href='#'><FaYoutube /></a>
        </div>
        <div className="footer-line"></div>
          <div className="footer-text">
            Copyright@ <a href='https://www.thefica.org'>www.thefica.org</a> All the website contents and materials and specific services and providers are original and belong to the Organization as exclusive right to further use online and offline. No duplication or copies of the informations below are permitted without that official authorization from the F.I.C.A main Office.
          </div>
        </div>
    </>
  );
}

export default Footer;