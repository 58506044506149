export const navLinks = [
  {
    id: "about",
    title: "About F.I.C.A.",
    navigateTo: 'about-fica',
  },
  {
    id: "aim",
    title: "Our Aim",
    navigateTo: '',
  },
  {
    id: "goals",
    title: "Our Goals",
    navigateTo: 'goals',
  },
  {
    id: "insights",
    title: "Insights",
    dropdown: [
      {
        id: 'strategicwork',
        title: 'Strategic Operational Work',
        navigateTo: 'insights/strategicwork',
      },
      {
        id: 'mainactivities',
        title: 'Main Activities on the Platform',
        navigateTo: 'insights/mainactivities',
      },
      {
        id: 'fundraisingplan',
        title: 'Fundraising Strategic Plan',
        navigateTo: 'insights/fundraisingplan',
      },
      {
        id: 'operationalplan',
        title: 'Operational Development Plan',
        navigateTo: 'insights/operationalplan?phase=1',
      },
    ],
  },
  {
    id: "solutions",
    title: "F.I.C.A. Solution",
    dropdown: [
      {
        id: 'decentralizedFinance',
        title: 'Decentralized Finance',
        navigateTo: 'solutions',
      },
      {
        id: 'smartBonds',
        title: 'Smart Bonds',
        navigateTo: 'solutions',
      },
      {
        id: 'eInvestment',
        title: 'E-Investment Insurance',
        navigateTo: 'solutions',
      },
      {
        id: 'smartContract',
        title: 'Smart Contracts & Crowdfunding',
        navigateTo: 'solutions',
      },
      {
        id: 'basketFundAndCrowdFund',
        title: 'Basket Fund Crowdfunding',
        navigateTo: 'solutions',
      },
      {
        id: 'tradeFinance',
        title: 'Trade Finance',
        navigateTo: 'solutions',
      },
    ],
  },
];
export const headerLinks = [
    {
      id: "overview",
      title: "Overview",
    },
    {
      id: "team-profile",
      title: "Team Profile F.I.C.A.",
    },
    {
      id: "board-team-member",
      title: "Board Team F.I.C.A.",
    },
    {
      id: "ourpartners",
      title: "Our Partners",
    },
    {
      id: "otherPartner",
      title: "Other's Partners",
    },
    {
      id: "networkingCountry",
      title: "Networking Countries",
    },
];
export const goals = [
    {
      id: "publicprivatepartnership",
      title: "Public-Private Partnership",
    },
    {
      id: "masterfranchising",
      title: "Master Franchising",
    },
    {
      id: "jointventure",
      title: "Joint Venture",
    },
    {
      id: "equitycapitalenter",
      title: "Equity and Capital Enter",
    },
    {
      id: "specialvehilcepurpose",
      title: "Special Vehicle Purpose",
    },
    {
      id: "businessownmanagement",
      title: "Business Own Management",
    },

    {
      id: "foreigndirectinvestment",
      title: "Foreign Direct Investment",
    },
    {
      id: "trading",
      title: "Trading",
    },
    {
      id: "mergingandacquisition",
      title: "Merging and Acquisition",
    },
    {
      id: "tradefinance",
      title: "Trade Finance",
    },
    {
      id: "internationalgrowth",
      title: "International Expansion Growth",
    },
    {
      id: "boutiqueinvestment",
      title: "Boutique Investment",
    },
];
export const insights = [
  {
    id: '1',
    title: 'Strategic Operational Work',
    redirect: '/insights/strategicwork',
},
{
    id: '2',
    title: 'Main Activities On The Platform ',
    redirect: '/insights/mainactivities',
},
{
    id: '3',
    title: 'Fundraising Strategic Plan',
    redirect: '/insights/fundraisingplan',
},
{
    id: '4',
    title: 'Operational Development Plan',
    redirect: '/insights/operationalplan',
},
];
export const teamProfile = [
  {
    id: '1',
    name: 'Gopal Ghirish',
    position: 'Consultant',
    info: 'I have been working as independent consultant from October 2010 till 2017. These seven years spent alone have  helped me develop entrepreneurial skills, negotiation with foreign parties, better understanding of the financial  services sector and along with consulting.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/gopalGhirish.jpeg'
  },
  {
    id: '2',
    name: 'Jean Bosco CHENDJOU',
    position: 'Project Manager Assistant',
    info: 'Marketing and communication professional with ten years experience Keep learning. Successful  deployment of operational marketing campaigns with teams. Team spirit and leadership skills to motivate  teams. Effective multitasking while meeting deadlines.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/jeanBosco.jpeg'
  },
  {
    id: '3',
    name: 'Daily Ramasia',
    position: 'Project Manager Assistant',
    info: 'Daily is a renowned business and professional woman. Passionate about African economic growth that embrace the ethos of ESG. She an excellent investor to business leader connector, because of her strategic abilities to see potential and growth in various sectors that contributes to the global economic value chain.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/dailyRamasia.jpeg'
  },
  {
    id: '4',
    name: 'Mr. Philip UZUMA NNGANYADI',
    position: 'General Secretariat',
    info: 'Environmental & government affairs cum EHSS Coordinator for Europe, Central & Eastern Europe, Middle East & Africa. Product knowledge, & exceptional linguistic Skills.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/teamFICA/philipUzuma.jpeg'
  },
];
export const boardMembers = [
  {
    id: '1',
    name: 'HM MAFO TICHA POUOKAM MPELE IV',
    position: 'President and Founder of F.I.C.A.',
    info: 'Her Majesty is an International Expert on Financial Good Governance. President of FICA International Federation of Consult Chambers for Africa focus on Economy Diplomacy.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/hm_mafo.jpeg'
  },
  {
    id: '2',
    name: 'Mr. Chakib Zhouaghi',
    position: 'Counsellor',
    info: 'A Legacy of Sustainable Development and Global Impact .He has carved a remarkable niche in the arena of sustainable development.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/chakibZhouaghi.png'
  },
  {
    id: '3',
    name: 'Mr. Desmopnd TAY',
    position: 'Counsellor',
    info: 'Desmond Tay is the current Chief Executive Officer of GUUD. He previously worked at Visiflex Pte Ltd as a Founder and Managing Director. Desmond Tay attended Ngee Ann Polytechnic.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/desmopndTay.jpeg'
  },
  {
    id: '4',
    name: 'Mr. Badir AL MUSHREKH',
    position: 'Counsellor',
    info: 'Desmond Tay is the current Chief Executive Officer of GUUD. He previously worked at Visiflex Pte Ltd as a Founder and Managing Director. Desmond Tay attended Ngee Ann Polytechnic.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/badirAl.jpeg'
  },
  {
    id: '5',
    name: 'Mr. Allen WU',
    position: 'Counsellor',
    info: 'Is a Chinese American serial entrepreneur and investor with decades in multiple sectors including natural resources around the world. Allen is CEO of BZG Asia Investment LLC, a partner in Bin Zayed Natural Resources LLC.',
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/allenWu.jpeg'
  },
  {
    id: '6',
    name: 'Mrs. Lety Eposi Endeley',
    position: 'Central Director',
    subPosition: 'Central Director',
    info: "Lety is the Central Director Non-life for International Markets at CICA RE Lome, Togo, where she is responsible for enhancing the company's vision and strategies for Anglophone Africa, the Middle East, Asia, and Latin America.",
    image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/letyEposi.jpeg'
  },
];

export const partners = [
  {
    title : 'Our Partners',
    images: [
      {
        id: '1',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/AFCTA.png'
      },
      {
        id: '2',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/UNIDO.jpeg'
      },
      {
        id: '3',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/FAGACE.jpeg'
      },
      {
        id: '4',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/SCE.jpeg'
      },
      {
        id: '5',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/eSingapore.jpeg'
      },
      {
        id: '6',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/UNECA.jpeg'
      },
      {
        id: '7',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/MFA.jpeg'
      },
      {
        id: '8',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/China.jpeg'
      },
      {
        id: '9',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/mSingapura.jpeg'
      },
      {
        id: '10',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/img1.jpeg'
      },
      {
        id: '11',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/img2.jpeg'
      },
      {
        id: '12',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/img3.jpeg'
      },
      {
        id: '13',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/binzayed.jpeg'
      },
      {
        id: '14',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/bagoInvestment.jpeg'
      },
    ]
  },
  {
    title : "Other's Partners",
    images: [
      {
        id: '1',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/NFSA.jpeg'
      },
      {
        id: '2',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/ICS.jpeg'
      },
      {
        id: '3',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/AIM.jpeg'
      },
      {
        id: '4',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/UECDE.jpeg'
      },
      {
        id: '5',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/ATPV.jpeg'
      },
      {
        id: '6',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/supportingPartner.jpeg'
      },
      {
        id: '7',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/otherPartners/lotusDevelopment.jpeg'
      },
    ]
  },
  {
    title : 'Networking Countries',
    images: [
      {
        id: '21',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country21.jpeg',
        imageTitle: 'Equatorial Guinea',
      },
      {
        id: '8',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country8.jpeg',
        imageTitle: 'Republic of Mozambique',
      },
      {
        id: '1',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country1.jpeg',
        imageTitle: 'Zimbabwe',
      },
      {
        id: '4',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country4.jpeg',
        imageTitle: 'Central African Republic'
      },
      
      {
        id: '6',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country6.jpeg',
        imageTitle: 'Seychelles'
      },
      {
        id: '5',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country5.jpeg',
        imageTitle: 'Kenya'
      },
      {
        id: '19',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country19.jpeg',
        imageTitle: 'Chad'
      },
      {
        id: '24',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country24.jpeg',
        imageTitle: 'Ethiopia'
      },
      {
        id: '12',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country12.jpeg',
        imageTitle: 'Ghana'
      },
      {
        id: '17',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country17.jpeg',
        imageTitle: 'Gabon'
      },
      {
        id: '22',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country22.jpeg',
        imageTitle: 'Eswatini'
      },
      {
        id: '13',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country13.jpeg',
        imageTitle: 'Namibia'
      },
      {
        id: '7',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country7.jpeg',
        imageTitle: 'Banner o Burundi'
      },
      {
        id: '3',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country3.jpeg',
        imageTitle: 'Democratic Republic of the Congo'
      },
      {
        id: '11',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country11.jpeg',
        imageTitle: 'Presidential Standard of Mauritius'
      },
      {
        id: '14',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country14.jpeg',
        imageTitle: 'Serbia'
      },
      {
        id: '10',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country10.jpeg',
        imageTitle: 'Netherlands'
      },
      {
        id: '25',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country25.jpeg',
        imageTitle: 'Malaysia'
      },
      {
        id: '18',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country18.jpeg',
        imageTitle: 'Botswana'
      },
      {
        id: '27',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country27.jpeg',
        imageTitle: 'Coat of arms of Togo'
      },
      {
        id: '23',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country23.jpeg',
        imageTitle: 'Government of South Africa'
      },
      {
        id: '2',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country2.jpeg',
        imageTitle: 'Tanzania'
      },
      {
        id: '26',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country26.jpeg',
        imageTitle: 'Coat of arms of Cameroon'
      },
      {
        id: '9',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country9.jpeg',
        imageTitle: 'Madagascar'
      },
      {
        id: '20',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country20.jpeg',
        imageTitle: 'Coat of arms of Mali'
      },
      {
        id: '16',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country16.jpeg',
        imageTitle: 'Royal Bafokeng Nation'
      },
      {
        id: '15',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/networkingCountries/country15.jpeg',
        imageTitle: 'Lesotho'
      },
    ]
  },
]
export const aboutPartners = [
  {
    title : 'FICA Partners',
    images: [
      {
        id: '1',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/ficaParnter/africanCapacity.png'
      },
      {
        id: '2',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/ficaParnter/federalDemocrative.png'
      },
    ]
  },
  {
    title : 'Development Partners',
    images: [
      {
        id: '1',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/EU.png'
      },
      {
        id: '2',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/DGDEVCO.png'
      },
      {
        id: '3',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/UNECA.jpeg'
      },
      {
        id: '4',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/ADB.png'
      },
      {
        id: '5',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/UNWomen.png'
      },
      {
        id: '6',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/ILO.png'
      },
      {
        id: '7',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/worldBank.png'
      },
      {
        id: '8',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/ESAAA.png'
      },
      {
        id: '9',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/UNDP.png'
      },
      {
        id: '10',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/AUC.png'
      },
      {
        id: '11',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/CEMAC.png'
      },
      {
        id: '12',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/USAIND.png'
      },
      {
        id: '13',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/EUEA.png'
      },
      {
        id: '14',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/partners/ourPartners/UNIDO.jpeg'
      },
      {
        id: '15',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/ADBG.png'
      },
      {
        id: '16',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/FAOUN.png'
      },
      {
        id: '17',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/GIZ.png'
      },
      {
        id: '18',
        image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/about/developmentParnter/GPE.png'
      },
    ]
  },
]
export const serviceProvider = [
  {
    id: '1',
    title: 'Packaging'
  },
  {
      id: '2',
      title: 'Logistic Transportation'
  },
  {
      id: '3',
      title: 'Warehousing'
  },
  {
      id: '4',
      title: 'Financing'
  },
  {
    id: '5',
    title: 'Cross-border compliance'
  },
];
export const boardProfileFICA = [
  {
    id: '1',
    title: 'HM MAFO TICHA POUOKAM MPELE IV',
    redirect: '/board-team-member/HM-MAFO-TICHA-POUOKAM-MPELE-IV',
  },
  {
    id: '2',
    title: 'Mr. Chakib Zhouaghi',
    redirect: '/board-team-member/Mr.-Chakib-Zhouaghi',
  },
  {
    id: '3',
    title: 'Mr. Desmopnd TAY',
    redirect: '/board-team-member/Mr.-Desmopnd-TAY',
  },
  {
    id: '3',
    title: 'Mr. Badir AL MUSHREKH',
    redirect: '/board-team-member/Mr.-Badir-AL-MUSHREKH',
  },
  {
    id: '3',
    title: 'Mr. Allen WU',
    redirect: '/board-team-member/Mr.-Allen-WU',
  },
  {
    id: '3',
    title: 'Mrs. Lety Eposi Endeley',
    redirect: '/board-team-member/Mrs.-Lety-Eposi-Endeley',
  },
]
export const teamProfileFICA = [
  {
    id: '1',
    title: 'Gopal Ghirish',
    redirect: '/team-profile/Gopal-Ghirish?pageNo=1',
  },
  {
      id: '2',
      title: 'Jean Bosco CHENDJOU',
      redirect: '/team-profile/Jean-Bosco-CHENDJOU',
  },
  {
      id: '3',
      title: 'Daily Ramasia',
      redirect: '/team-profile/Daily-Ramasia',
  },
  {
    id: '3',
    title: 'Mr. Philip UZUMA NNGANYADI',
    redirect: '/team-profile/Mr.-Philip-UZUMA-NNGANYADI?pageNo=1',
  },
];
export const netWorkingCountry = [
  {
    id: '1',
    title: 'Equatorial Guinea'
  },
  {
      id: '2',
      title: 'Republic of Mozambique'
  },
  {
      id: '3',
      title: 'Zimbabwe'
  },
  {
      id: '4',
      title: 'Central African Republic '
  },
  {
    id: '5',
    title: 'Seychelles'
  },
  {
    id: '6',
    title: '+22 More Countries'
  },
];
export const aboutFICA = [
  {
    id: '1',
    title: 'About F.I.C.A.',
    redirect: '/about-fica'
  },
  {
      id: '3',
      title: 'F.I.C.A. Context',
      redirect: '/about'
  },
];
export const footerParnter = [
  {
    id: '1',
    title: 'Our Partners - 14',
    redirect: '/',
    navState: {scrollVal: 'ourpartners', activeTab: 'ourpartners'},
  },
  {
    id: '2',
    title: "Other's Partners - 7",
    redirect: '/',
    navState: {scrollVal: 'ourpartners', activeTab: 'otherPartner'},
  },
  {
    id: '3',
    title: "Networking Countries - 27",
    redirect: '/',
    navState: {scrollVal: 'ourpartners', activeTab: 'networkingCountry'},
  },
];
export const ficaSolutions = [
  {
    id: 'solutions0',
    title: 'Decentralised Finance',
    redirect: '/solutions',
    navState: {scrollVal: 'decentralizedFinance'}
  },
  {
    id: 'solutions1',
    title: 'Smart Bonds',
    redirect: '/solutions',
    navState: {scrollVal: 'smartBonds'}
  },
  {
    id: 'solutions2',
    title: 'E-Investment Insurance',
    redirect: '/solutions',
    navState: {scrollVal: 'eInvestment'}
  },
  {
    id: 'solutions3',
    title: 'Smart Contracts & Crowdfunding',
    redirect: '/solutions',
    navState: {scrollVal: 'smartContract'}
  },
  {
    id: 'solutions4',
    title: 'Basket Fund Crowdfunding',
    redirect: '/solutions',
    navState: {scrollVal: 'basketFundAndCrowdFund'}
  },
  {
    id: 'solutions6',
    title: 'Trade Finance',
    redirect: '/solutions',
    navState: {scrollVal: 'tradeFinance'}
  },
];
export const BoardMemberData = [
  {
      id: 'Mr.-Chakib-Zhouaghi',
      image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/chakibZhouaghi.png',
      title: 'Counsellor',
      oneLiner: [
          "Mr. Chakib Zouaghi: A Legacy of Sustainable Development and Global Impact",
          'A distinguished development Agroeconomist, Mr. Chakib Zouaghi has carved a remarkable niche in the arena of sustainable development. Currently, serving as the UN DAF/CCA/CF Partnership & Programme Specialist at the Regional United Nations.',
          'Development Coordination Office (UNDCO) Arab States, he brings to the table an astounding 24 years of hands-on experience. This spans a myriad of domains, from programme design and management to investment for sustainable development.',
          "Prior to this illustrious position, Chakib's journey saw him at the helm of the Resident Coordinator's Office in Egypt, where he lent his expertise as the Development Economics Specialist. His global footprints extend across the Arab States and Africa, marking his tenure with esteemed institutions like IFAD, FAO, the World Bank, and GIZ.",
          "A true polymath, Chakib has orchestrated a range of programmes dedicated to agriculture, climate change, microfinance, youth, gender, urban planning, and nutrition. His underlying goal? To catalyze economic transformation, LNOB, and enhancing Human Capital.",
          "The academia too has had the privilege of his wisdom. Chakib, a national of both Canada and Tunisia, has donned the professorial mantle at reputed institutions like Ottawa University and the University of Sherbrooke in Canada. Multilingual and culturally attuned, he is fluent in Arabic, English, French, and Italian.",
      ],
  },
  {
      id: 'Mr.-Desmopnd-TAY',
      image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/desmopndTay.jpeg',
      title: 'Counsellor',
      oneLiner: [
          "Mr. Desmond TAY \nSingaporean National",
          'Desmond Tay is the current Chief Executive Officer of GUUD. He previously worked at Visible PTE Ltd as a Founder and Managing Director. Desmond Tay attended Ngee Ann Polytechnic.',
          "Desmond is a serial Entrepreneur and investor. He states that the company started with a grand vision to change global trade through digitalization.",
          "To accomplish this vision, the company needs to be a leader in the transformation of the trade ecosystem, a thought leader in the field of digital technology and most important of all, visionary leadership in the organization to motivate a pool of talents to achieve the company's goals.",
          "Further, the company's success is not achieved through a single leader, but a core leadership team in VCC. He is glad that, over the years, he has been able to build this core team to walk the journey with him.",
      ],
  },
  {
      id: 'Mr.-Badir-AL-MUSHREKH',
      image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/badirAl.jpeg',
      title: 'Counsellor',
      oneLiner: [
          "Badir A. Almusharrekh \nUAE National",
          "Chief Urban Planning Consultant, UAE Telecommunications Company (10 years)",
          "Director of Business Development (Marine Sector), Dubai Ports (3 years)",
          "Director of International Economic and Trade Organizations, UAE Ministry of Economy (2009-2021).",
          "Commercial Attache, Geneva, Switzerland (5 years)",
          "Commercial Attache, Director of UAE Office to WTO, Geneva, Switzerland(5 years)",
          "Commercial Attache, UAE Embassy in China (4 years)",
          "Entrepreneurship: \nCo-founder, BAGO LLC \nCo-founder, BAGO Strategic Consulting \nInvestments in various business sectors: fashion, food, e-commerce, furniture, and nonprofitcompanies",
          "Education: \nMaster's Degree in Global Business Strategy. \nProvided consultation for companies in UAE, China, Egypt, Spain, UK, Switzerland, Somalia, and South AfricaThis curriculum highlights Mr. Badir A. Almusharrekh's extensive experience in various sectors and his entrepreneurial ventures, showcasing a robust background in business strategy and international trade.",
      ],
  },
  {
      id: 'Mr.-Allen-WU',
      image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/allenWu.jpeg',
      title: 'Counsellor',
      oneLiner: [
          "Mr. Allen Wu",
          'Is a Chinese American serial entrepreneur and investor with decades in multiple sectors including natural resources around the world.',
          'Allen is CEO of BZG Asia Investment LLC, a partner in Bin Zayed Natural Resources LLC.',
          "Allen is founding Chairman of Assetera Ecosys Pte Ltd that tokenizes tangible and intangible assets worldwide for trading and settling via its German bank.",
          "Allen is also founding Chairman of Lotus Energy Ltd deeply involved in the EV sector from upstream in minerals to midstream EV products and services to downstream in recycling. Allen has B.A. in International Economics and International Cultural Exchanges and M.A. in International Relations.",
      ],
  },
  {
      id: 'Mrs.-Lety-Eposi-Endeley',
      image: 'https://fica-data.s3.ap-south-1.amazonaws.com/img/boardMembers/letyEposi.jpeg',
      title: `Central Director \nNon-life for International Markets at CICA RE Lome`,
      oneLiner: [
          "Lety Eposi Endeley is the central Director for Anglophone Africa, Asia & Latin America, CICA- Re Lome, Togo. She has over 27 years of experience in the Insurance Reinsurance industry. She has a record of achievements in senior management positions including Chief Underwriter, Anglophone Africa in CICA-RE Regional Director for Central African Markets, and Managing Director Continental Re, Cameroon.",
          'Mrs. Lety Eposi Endeley \nCameroonian National',
          "Lety is the Central Director Non-life for International Markets at CICA RE Lome, Togo, where she is responsible for enhancing the company's vision and strategies for Anglophone Africa, the Middle East, Asia, and Latin America.",
          "Lety is a seasoned reinsurance executive with more than 30 years of demonstrated professional and leadership experience in managing teams in both French and English-speaking workplaces.",
          "She has ten years of direct insurance experience in customer service, claims, technical, and reinsurance.",
          "She also worked as an underwriter for ten years, rising to the position of Chief Underwriter and, later, Manager for Anglophone Africa at CICA RE, TOGO",
          "Prior to rejoining CICA Re in 2020, she was Regional Director for Continental Re in Douala, Cameroon, in charge of the Central African region, and then Managing Director for the CIMA Markets between 2012 and 2019.",
          "-She is a member of the FIDA Cameroun (Federation of International Female Lawyers)",
          "- Board Member, Corporate Awards Gabon & Cameroon; Executive Director of Women in Leadership Central Africa.",
          "Vice President (Central Africa Region) & Executive Chair, Legal / Compliance Committee, AIWA, (Africa Insurance Women Organisation) where her passion lies on connecting African women in insurance for a greater impact.",
      ],
  },
];