import React from 'react'

function SuccessStrategy() {
  return (
    <div className='subheading-content-wrapper'>
      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>1</span>
        <span className='fundraising-body-data'>Increase Awareness and Visibility</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>2</span>
        <span className='fundraising-body-data'>Promoted FICA as part of an integrated Great-lakes African Mega Region Integration.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>3</span>
        <span className='fundraising-body-data'>See through the eyes of the target market - Re-position FICA strengths based on investors needs in the target sectors. E.g. provide specific examples of utility arising from the Port and Airport, research and development opportunities & synergies with other industries.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>4</span>
        <span className='fundraising-body-data'> <b>Multi-stakeholder partnerships</b> Enhance the global partnership for sustainable development, complemented by multi stakeholder partnerships that mobilize and share knowledge, expertise, technology and financial resources, to support the achievement of the sustainable development goals in all countries, on particular developing countries Encourage and promote effective public, public private and civil society partners hps building on the experience and resourcing strategies of partnerships.</span>      
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>5</span>
        <span className='fundraising-body-data'>Participate and make joint representations in sector specific events, trade shows, seminars and investment meetings; and - Communicate and engage where it strategically makes sense with non government agencies (e.g. Lead generation firms), community organizations (e.g. the invest FICA partners and key economic developmental institutions)</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>6</span>
        <span className='fundraising-body-data'>Ensuring investment readiness; Proactive and Integrated approach to Tracking and Securing Investment.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>7</span>
        <span className='fundraising-body-data'>Best practices include the regular participation of senior management and staff, including governments, in trade missions and other outreach pursuits.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>8</span>
        <span className='fundraising-body-data'>Once a target is engaged and considering an investment, the ability to identify available incentives and facilitate timely approvals can often be the difference between success or failure. Action items.</span>
      </div>

      <div className='subheading-data'>
        <span className='fundraising-body-numbering'>9</span>
        <span className='fundraising-body-data'>African Countries agree to joint FICA on the “One-Stop for Businesses” concept and seek to expedite development approvals.</span>
      </div>

    </div>
  )
}

export default SuccessStrategy