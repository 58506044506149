import React, { useEffect } from 'react';

const Help = () => {

  useEffect(() => {
    scorllToTop()
  }, [])

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  const get_url = () => {
    const pathLocation = window.location.host;
    let baseUrl = 'http://ficadev.s3-website.ap-south-1.amazonaws.com'
    if(!!pathLocation) {
      if(pathLocation==='thefica.org') {
        baseUrl = 'https://thefica.org'
      } else {
        baseUrl = 'http://ficadev.s3-website.ap-south-1.amazonaws.com'
      }
    }
    return baseUrl
  }

  const get_mail = () => {
    const pathLocation = window.location.host;
    let baseMail = 'f0c6e27379036ef443a3ecb4d1b0d2e2'
    if(!!pathLocation) {
      if(pathLocation==='thefica.org') {
        baseMail = 'info@fica.io'
      } else {
        baseMail = 'f0c6e27379036ef443a3ecb4d1b0d2e2'
      }
    }
    return baseMail
  }

  return (
    <>
      <div className="help-container">
        <h2>We're Here to Help</h2>
        <p>
          We are committed to promoting good governance at all levels of African
          societies. We are committed to supporting men & women sharing the
          concept of shared value, able to place the general interest of our
          societies, at the heart of any commitment.
        </p>
        <div className="contact-section">
          <div className="contact-block">
            <h3>Call Us</h3>
            <p><a href="tel:(251) 11 82 31317">+ (251) 11 82 31317</a></p>
            <p><a href="tel:(91) 9650 110 080">+ (91) 9650 110 080</a></p>
          </div>
          <div className="contact-block">
            <h3>Send Message</h3>
            <p><a href="mailto:secretariat@thefica.org">secretariat@thefica.org</a></p>
            <p><a href="mailto:abhinav.puri@fica.io">abhinav.puri@fica.io</a></p>
            <p><a href="mailto:info@fica.io">info@fica.io</a></p>
          </div>
          <div className="contact-block">
            <h3>Our Location</h3>
            <p>BP2669, L-1026, Luxembourg</p>
            <p>P.O.Box 14266, Bole Sub City,</p>
            <p>Woreda, 03 House #288 Addis Ababa, Ethiopia</p>
          </div>
          <div className="contact-block">
            <h3>Working Hours</h3>
            <p>Every day</p>
            <p>8:00 - 22:00</p>
          </div>
          <div className="contact-block">
            <h3>Get in touch on WhatsApp Business</h3>
            <p><a href="tel:(+91) 74284 50088">(+91) 74284 50088</a></p>
          </div>
        </div>
      </div>
      <div className="contact-form-container">
         <form action={`https://formsubmit.co/${get_mail()}`} method='POST'>
           <div className="form-group">
             <label htmlFor="name">Your Name (Required)</label>
             <input type="text" id="name" name="name" placeholder="Enter your name" required/>
           </div>
           <div className="form-group">
             <label htmlFor="email">Your Email (Required)</label>
             <input type="email" id="email" name="email" placeholder="Enter your email" required/>
           </div>
           <div className="form-group">
             <label htmlFor="message">Your Message</label>
             <textarea name='message' id="message" placeholder="Write" rows="4" maxLength={"1000"}></textarea>
           </div>
           <input type="hidden" name="_captcha" value="false"/>
           <input type="hidden" name="_template" value="box"/>
           <input type="hidden" name="_next" value={`${get_url()}/thank-you`}/>
           <p className="disclaimer">This form is not to be used for advertising purposes.</p>
           <button type="submit" className="submit-button">Send Message</button>
         </form>
       </div>
    </>
  );
}

export default Help;
