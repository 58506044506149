import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {LeftOutlined, RightOutlined } from '@ant-design/icons'

function OperationalPlan({NavigationButtons}) {

  const location = useLocation()
  const navigate = useNavigate()
  const [pageNo, setPageNo] = useState(0)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const pageNo = queryParams.get('phase') || 0
    if(pageNo!=0) {
      if(!pageNo || Number(pageNo)>5 || Number(pageNo)<1 || isNaN(Number(pageNo))) {
        navigate('/not-found')
      }
    }
    setPageNo(Number(pageNo))
    scorllToTop()
  }, [location.search])

  const handleNext = () => {
    if (pageNo < 5) {
      const newPageNo = pageNo + 1
      setPageNo(newPageNo)
      navigate(`/insights/operationalplan?phase=${newPageNo}`)
    }
  }

  const handlePrev = () => {
    if (pageNo > 1) {
      const newPageNo = pageNo - 1
      setPageNo(newPageNo)
      navigate(`/insights/operationalplan?phase=${newPageNo}`)
    }
  }

  const scorllToTop = () => {
    window.scrollTo({
        top : 0,
        behavior : "smooth"
    })
  }

  return (
    <>
    {pageNo===0 && 
    <div className='operational-plan-container'>
      <h2 className='page-heading'><span className='number'>4</span> Operational Development Plan</h2>
      <div className='operational-container'>
        <div className='phase-container'>
          <h3 className='phase-title'>Phase 1:</h3>
          <p className='pointers'>1. Road Show Program</p>
          <p className='pointers'>2. Project Development Program</p>
          <Link to={'/insights/operationalplan?phase=1'} className='learn-more'>Learn More</Link>
        </div>
        <div className='right-conatiner'>
          <div className='phase-container'>
            <h3 className='phase-title'>Phase 2:</h3>
            <p className='pointers'>1. Community Partnerships</p>
            <Link to={'/insights/operationalplan?phase=2'} className='learn-more'>Learn More</Link>
          </div>
          <div className='phase-container'>
            <h3 className='phase-title'>Phase 3:</h3>
            <p className='pointers'>1. Diaspora Entrepreneurs</p>
            <Link to={'/insights/operationalplan?phase=3'} className='learn-more'>Learn More</Link>
          </div>
          <div className='phase-container'>
            <h3 className='phase-title'>Phase 4:</h3>
            <p className='pointers'>1. Outreach Priorities</p>
            <Link to={'/insights/operationalplan?phase=4'} className='learn-more'>Learn More</Link>
          </div>
          <div className='phase-container'>
            <h3 className='phase-title'>Phase 5:</h3>
            <p className='pointers'>1. Tracking Success</p>
            <Link to={'/insights/operationalplan?phase=5'} className='learn-more'>Learn More</Link>
          </div>
        </div>
      </div>

      <NavigationButtons 
        showPrev={true}
        showNext={false}
        prevAddress={'fundraisingplan'}
        nextAdress={''}/>
    </div>}

    {pageNo!==0 && 
    <>
    <div className='insight-hero-container'>
      <h3 className='hero-heading'>4. Operational Development Plan</h3>
      {/* <img src={`${process.env.PUBLIC_URL}/img/insights/insight-phase-bg.png`} className='insight-hero-img' alt='Logo'/> */}
    </div>
    <div className='insight-page-container'>
      
      <div className='page-buttons-container'>
        <Link to={'/insights/operationalplan?phase=1'} className={`page-btn ${pageNo===1 && 'active'}`}>Phase 1</Link>
        <Link to={'/insights/operationalplan?phase=2'} className={`page-btn ${pageNo===2 && 'active'}`}>Phase 2</Link>
        <Link to={'/insights/operationalplan?phase=3'} className={`page-btn ${pageNo===3 && 'active'}`}>Phase 3</Link>
        <Link to={'/insights/operationalplan?phase=4'} className={`page-btn ${pageNo===4 && 'active'}`}>Phase 4</Link>
        <Link to={'/insights/operationalplan?phase=5'} className={`page-btn ${pageNo===5 && 'active'}`}>Phase 5</Link>
      </div>
      <div className='data-container'>
        <LeftOutlined onClick={handlePrev} style={{color:'#6D6D6D', fontSize:'2.5rem'}} className='page-nav-btn' />
        <div className='insight-data-container'>
          {pageNo===1 && 
          <>
          <div className='insight-data' style={{backgroundColor:'#D6EFEB80'}}>
            <h4 className='data-heading'>1. Road Show Program</h4>
            <ul className='list-pointers'>
              <li className='list-item'>Deliverable Implement a road show campaign to emphasize FICA Success stories to inform existing firms and targets outside Africa of African countries' potential.</li>
              <li className='list-item'>Host a series a breakfast events with foreign owned firms to understand their export requirements.</li>
              <li className='list-item'>Retain a road show firm to improve and make FICA branding more consistent. Build on the “Ambitious African Countries” brand.</li>
              <li className='list-item'>Ensure industry specific sections of the website are up-to-date. Highlight current activities specifically as they relate to FICA target sectors.</li>
              <li className='list-item'>Undertake an assessment of FICA track record relative to national resources and benchmark with competing communities.</li>
            </ul>
          </div>
          <div className='insight-data' style={{backgroundColor:'#D6EFEB80'}}>
            <h4 className='data-heading'>2. Project Development Program</h4>
            <ul className='list-pointers'>
              <li className='list-item'>Action Timeline Deliverable Create a formal senior management team to meet with FICA prospects at the initial stages of site selection and facilitate approvals.</li>
              <li className='list-item'>Promote in FICA’s marketing materials, unique R&D assets associated with institutional partners especially McMaster University and emphasize materials, automotive and manufacturing.</li>
              <li className='list-item'>Market academic / industry partnerships as a means to demonstrate existing synergies.</li>
              <li className='list-item'>Undertake an advertising campaign including issuing a newsletter similar to recent initiative “Business Link” advertising industrial and commercial activity.</li>
              <li className='list-item'>Incorporate the work of the Global FICA to promote the concept as a welcoming. Highlight the broad array of language interpretation services and settlement services for newcomers.</li>
            </ul>
          </div>
          </>}

          {pageNo===2 && 
          <>
          <div className='insight-data' style={{backgroundColor:'#FAF0FF'}}>
            <h4 className='data-heading'>1. Community Partnerships</h4>
            <ul className='list-pointers'>
              <li className='list-item'><span className='brown'>Action Timeline Deliverable</span> Implement a series of export-oriented events for local entrepreneurs/companies with the intent of highlighting the advantages of exporting. As an outcome of the events, identify firms that would like to export and help them develop an export entry strategy.</li>
              <li className='list-item'>Continue to leverage relationships with educational facilities such as Masterclass in order to use their technical capabilities and connections as a marketing tool to potential FICA candidates. Continue to strengthen relationships with economic development organizations such as OCDE, WTO, UNC TAD, etc...</li>
              <li className='list-item'>Seek to accompany them on trade missions with the objective of positioning Investment Monitor as a strong driver for FICA location. Continue to strengthen the existing relationships with African Regional organizations’ in order to “stay on the radar” and leverage their lead generation networks. Provide quarterly updates to federal and provincial partners on FICA activity and success stories.</li>
            </ul>
          </div>
          <div className='insight-data' style={{backgroundColor:'#FAF0FF'}}>
            <ul className='list-pointers'>
              <li className='list-item'><span className='brown'>Action Timeline Deliverable</span> Continue to strengthen relationships and work with Master Class and the network and health research infrastructure to implement recommendations of Agriculture for incubator space Work in conjunction with post-secondary institutions to promote the breadth of talent available within the African region Actively engage both the business and cultural communities in the ‘attraction’ strategy using partnerships to identify investment opportunities.</li>
              <li className='list-item'>Develop and   market critical mass in steel processing and engineering capabilities at   Master Class with a view to creating partnerships between Countries, the  institution and potential FICA  targets establish a   champion or chief advocate for the establishment of  Agricultural industry cluster in Africa Partner with   Foreign Companies to promote various sectors overall for increased investment  and becoming a lead for Africa.</li>
            </ul>
          </div>
          </>}

          {pageNo===3 && 
          <>
          <div className='insight-data' style={{backgroundColor:'#ECFAFF'}}>
            <h4 className='data-heading'>1. Diaspora Entrepreneurs</h4>
            <ul className='list-pointers'>
              <li className='list-item'>Identify Political Ambassadors for actively engaging migrant entrepreneurs and communities.</li>
              <li className='list-item'>Initiate a marketing campaign for investors/entrepreneurs to raise awareness with respect to FICA diverse population and low cost of living and available social services Gain a clear understanding of diaspora networks in the America/Asia/Europe/UAE regions with the goal of promoting success stories to like-minded migrant investors in Africa.</li>
              <li className='list-item'>Leverage the migrant entrepreneur assistance programs to attract investment in the short-medium term Monitor and support improvements to the Countries’ “Open for Business” strategy particularly with respect to migrant entrepreneurs.</li>
              <li className='list-item'>Establish a Marketing campaign for “International Students” as part of the future migrant entrepreneurs.</li>
            </ul>
          </div>
          </>}

          {pageNo===4 && 
          <>
          <div className='insight-data' style={{backgroundColor:'#F2F2F2'}}>
            <h4 className='data-heading'>1. Outreach Priorities</h4>
            <ul className='list-pointers'>
              <li className='list-item'>Action Timeline Deliverable Formalize the investment in FICA partnership as an advisory group to collaborate, identify priorities; share in resources; targets and provide direction & to assist in our application for funding The programs.</li>
              <li className='list-item'>Formalize an official FICA Lead and outreach program with the intent of clearly identifying the trade missions and events that are top priorities.</li>
              <li className='list-item'>Undertake trade   missions to high priority FICA  locations with the intent of highlighting Africa as a premiere destination for investment.</li>
              <li className='list-item'>Maintain and  solidify provincial and federal relationships along with key trade  representatives and economic development agencies Identify and target companies that have come to Africa, but do not yet have an  African presence Create a ‘Welcome to FICA Initiative (Community Services, Marketing assistance, business support) to ensure smooth transition and explore additional opportunities</li>
            </ul>
          </div>
          <div className='insight-data' style={{backgroundColor:'#F2F2F2'}}>
            <ul className='list-pointers'>
              <li className='list-item'>Action Timeline Deliverable Identify and target large or high profile steel companies internationally that do not yet have African presence Focus on Singapore, China, Indian, UAE higher value manufacturing companies, particularly food processing value-added companies with expansion plans Identify opportunities for waste management, reduction, and recycling sectors as future FICA targets. Contact existing waste facilities to determine the process for accessing waste as input to these various sectors.</li>
              <li className='list-item'>Continue to conduct regular investment familiarization tours & participate in economy diplomacy focused activities & networks to ensure FICA is on the radar e.g.</li>
            </ul>
          </div>
          </>}

          {pageNo===5 && 
          <>
          <div className='insight-data' style={{backgroundColor:'#F0F5FF'}}>
            <h4 className='data-heading'>1. Tracking Success</h4>
            <ul className='list-pointers'>
              <li className='list-item'>Action Timeline Deliverable Continue to monitor standard business indicators along with FICA success stories and value of investments Track trends and changes in the demand for FICA by sector or geography that might be occurring Set a series of targets and timelines with respect to FICA attraction, including the attraction of high-profile users, export intensity or other forms of investment Establish a series of metrics to measure Return on Investment for attendance at trade shows and other promotional activities Benchmark.</li>
              <li className='list-item'>FICA achievements with competing countries in Africa, as part of the case for increased funding / resources continue to monitor and evaluate results of strategy and progress towards stated target</li>
            </ul>
          </div>
          </>}
        </div>
        <RightOutlined onClick={handleNext} style={{color:'#6D6D6D', fontSize:'2.5rem'}} className='page-nav-btn' />
      </div>
    </div>
    </>}
    </>
  )
}

export default OperationalPlan