import React, { useState } from 'react';
import { Engagement, TargetFocus, Background } from '..';

function Institution() {
  const [activeTab, setActiveTab] = useState('FICA Engagement');
  const tabs = [
    {
      id: '1',
      title: 'FICA Engagement',
      component: <Engagement instituional={true}/>,
    },
    {
      id: '2',
      title: 'FICA Target Focus',
      component: <TargetFocus />,
    },
    {
      id: '3',
      title: 'Background',
      component: <Background />,
    },
  ];
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className='padding-4'>
        <p>
          We at FICA undertake and commit to be socially responsible, our partners are committed Investors, statesmen, and experts who put their expertise to the service of a majority of their partners. FICA is an International Organization working for the preservation of Humanity, climate change, food security
          Health and welfare, capacity building in the field of technical training, disclosure of economic ecosystems, environmental protection, and development of inclusive & sustainable infrastructure & we, therefore, put our respective skills to work for economic and social development in Africa.
        </p>
        <div className="tab-container aboutfica">
          {tabs.map(tab => (
            <h3
              key={tab.title}
              className={`tab-item ${activeTab === tab.title ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.title)}
            >
              {tab.title}
            </h3>
          ))}
        </div>
      </div>
      <div className="tab-content">
        {tabs.find(tab => tab.title === activeTab)?.component}
      </div>
    </>
  );
}

export default Institution;
