import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { headerLinks } from '../constants'; 

function HeaderNavBar() {
  const [active, setActive] = useState('overview');
  const navigate = useNavigate();
  
  const handleClick = (id) => {
    setActive(id);
    navigate(`/`, {state: 
      {
        scrollVal: `${(id==='ourpartners' || id==='otherPartner' || id==='networkingCountry') ? 'ourpartners' : id}`,
        activeTab: `${(id==='ourpartners' || id==='otherPartner' || id==='networkingCountry') ? id : undefined}`
      }});
  };

  return (
    <div className='header-container'>
      <nav className='header'>
        <ul className='header-links'>
          {headerLinks.map((link) => (
            <li key={link.id}>
              <a 
                // className={link.id === active ? 'active' : ''}
                onClick={() => handleClick(link.id)}
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default HeaderNavBar;
