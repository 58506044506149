import React from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons'
import { insights } from '../../constants';

function Insights() {
  return (
    <div style={{ backgroundColor: '#F3F3F3' }}>
      <div className='insights-container'>
        <h2 className='insights-title'>Insights</h2>
        <div className='insights-content-wrapper'>
          <div className='insights-content'>
            {insights.map((link) => (
                <Link to={`/insights/${link.id==='1' ? 'strategicwork' : link.id==='2' ? 'mainactivities' : link.id==='3' ? 'fundraisingplan' : 'operationalplan'}`}
                  className='insights-line' key={link.id}
                >
                  <div style={{display:'flex', alignItems:'center'}}>
                    <span className='insights-numbering'>{link.id}</span>
                    <span className='insights-data'>{link.title}</span>
                  </div>
                  <RightOutlined style={{color:'#000', fontSize:'1.5rem'}}/>
                </Link>
            ))}
          </div>
          <img loading='lazy' className="insights-logo" src="https://fica-data.s3.ap-south-1.amazonaws.com/img/insights.png" alt="Logo" />
        </div>
      </div>
    </div>
  );
}

export default Insights;
