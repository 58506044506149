import React from 'react';


function StrategicOperationalWork({NavigationButtons}) {
  return (
   <>
    <div className='strategicwork-container'>
      <div className='strategicwork-heading'>
        <span className='strategicwork-numbering'>1</span>
        <span className='strategicwork-data'>Strategic Operational work </span>
      </div>
      <div className='strategicwork-content'>
        <ul className='strategicwork-ul'>
          <li className='strategicwork-li'>Africa needs Foreign Direct Investment partners to mutually invest in infrastructure to speed up the implementation of various projects with the government and private sector:</li>
          <li className='strategicwork-li'>The capital flight brought about by the needs to be appropriately reversed by better-developing infrastructure projects that would attract investments and offer attractive risk-adjusted returns.</li>
          <li className='strategicwork-li'>No doubt, most investors want to be sure that they will be made a fair investment and can freely operate infrastructure assets and meet service level targets and repatriate their profits when due.</li>
          <li className='strategicwork-li'>Offering of various finance instruments in our platform can also leverage the opportunity therein by providing risk-hedging instruments and credit enhancements, as well as supporting local currency financing to strengthen local capital markets.</li>
          <li className='strategicwork-li'>Advised African governments with a tough fiscal policy dilemma: managing trade-offs between pressing development-spending demands, limiting state debt, and rising opposition to tax revenue collection. Sub-Saharan Africa's expenditure demands are expanding and becoming more critical as the COVID-19 has a negative impact on health, employment, education, infrastructure investment, and poverty reduction initiatives. Climate change exacerbates the problem.</li>
          <li className='strategicwork-li'>Set up a Modern, comprehensive and coherent Integration of investment infra Africa and internationally.</li>
          <li className='strategicwork-li'>Promote an Investment Monitoring via the platform as the response which brings together all the different elements of the investment process.</li>
          <li className='strategicwork-li'>Established as a Team Expert working with governments to set up the framework and guidelines for investors, the team will drive its government-led technology through its value change on investment by providing greater visibility than ever before, and the creation of an inclusive network across the African continent. Through greater visibility, more robust value indicators and performance measures, the right data will be available for strategic decision-making.</li>
          <li className='strategicwork-li'>Set up a Daily Newsletter for investment marketplace in Africa that will have substantial benefits for African Governments and investors.</li>
        </ul>
      </div>
    </div>
    <NavigationButtons 
        showPrev={false}
        showNext={true}
        prevAddress={'/'}
        nextAdress={'mainactivities'}/>
   </>
  );
}

export default StrategicOperationalWork;
