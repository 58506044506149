import React from 'react'

function TargetFocus() {
  return (
    <>
      <div className='padding-4'>
        <p>The concept of governance was initially developed in the commercial sector, where the main aims were to prevent conflicts between shareholders and managers of a company. The objective being to make managers act to the interests of shareholders and not in their own interest. Codes of conduct have been developed for commercial companies (corporate governance code for listed companies in the financial markets, (Buysse code for SMEs). These codes contain a set of non-binding rules relating to the balance of powers, decision making, controls & responsibilities of these decisions making control mechanisms and responsibilities, FICA (International Federation of Consular chambers of commerce & Africa) embraces the concept of good governance especially within our African member states.</p>
      </div>
      <img loading='lazy' style={{width: '100%'}} src='https://fica-data.s3.ap-south-1.amazonaws.com/img/about/institutional/targetFocus.png' alt='targetFocus' />
      <div className='padding-4'>
        <p>In organizations, and more broadly in social benefit sector, there are no shareholders. The concept of good governance is not irrelevant either, quite to the contrary; if there are no shareholders, there are many involved, interested and affected parties at different levels as defined by the activities & membership of FICA, such as organization, local competent authority, beneficiary member states, investors/promoters, the staff, & many more …, thus we speak of stakeholders “founding members”. It is also, and above all, a social object. Thinking about good governance in social organizations, is to think about how decisions are made, rules, procedures of operation/functioning of the various bodies, parties involved in international acts & decisions, spread of information & respective/individual responsibilities towards the achievement social aim & goals. Some thus define good governance as “a management style that leads to expected social and economic outcomes stakeholders”, defining projects successfully executed & meet both immediate as also inclusive & sustainable development needs.</p>
        <p>FICA is an international organization open to all African countries; the central role of FICA is to support African countries to face the prospects for increased economic aggregates and social development. FICA is the first International Federation of Consular chambers for trade and commerce for Africa created to meet these needs & challenges.
        FICA represents the interests of the latter vis-à-vis political organizations and donors.
        <br />
        Through its international network, FICA has developed a cooperation platform to promote inclusive & sustainable development activities, contributing to the creation and management of public facilities needed for economic action in Africa. In her collaborative role, key partners & actors for progress in Africa are the African member states.
        </p>
      </div>

      <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 600,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        backgroundColor: '#E9F6FD',
        padding: '2rem'}}
        className='padding-4 padding-4-sm'>
        <p style={{fontWeight: '600'}}>1. The strategy orientation of FICA consists of several major axes of development in the African Continent:</p>

        <ul >
          <li>Promote inclusive & sustainable industrial development in Africa</li>
          <li>Propose & follow-up of a quality and secure investment in Africa</li>
          <li>Contribute to make the markets more accessible to medium & small size entrepreneurs & to re-enforce capacity building and competitiveness, Foster the transfer of skills in areas such as technical, project management, procurement & negotiation, Operational/manufacturing, financial/economic, (MDG 8)…….</li>
        </ul>
      </div>

      <div 
        style={{fontSize: '1.2rem',
        lineHeight: '2.4rem',
        fontWeight: 600,
        wordSpacing: '0.4rem',
        color: '#333',
        marginBottom: '20px',
        backgroundColor: '#ECECEC',
        padding: '4rem'}}
        className='padding-4 padding-4-sm'>
        <p style={{fontWeight: '600'}}>2. In her supportive role, FICA (International Federation of African Consular Chambers & Trade) focuses on the promotion and dissemination of good practice & we identify three (3) core values ​​of good governance:</p>

        <p><b style={{color: '#d4a14e'}}>1. Ethics:</b> inspire confidence amongst our donors, partners and subsidizing authorities. Responsible and transparent management of human, natural, economic and financial resources for inclusive sustainable and equitable human development.</p>
        <p><b style={{color: '#d4a14e'}}>2. Transparency:</b> As a basic condition for a democratic management of the projects entrusted to us by member countries (see Chapter IV), fair and impartial. The practice of collaborative governance approach favoring listening and dialogue between stakeholders, in order to lead to actions decided and undertaken by mutual agreement, by federating efforts & in full transparency.</p>
        <p><b style={{color: '#d4a14e'}}>3. Efficiency:</b> our organization operates with public funds, public subsidies, donations, sponsorship, contributions, and contributions from member countries. Good governance at the service of Development; A set of institutions and practices that ensure trust between rulers, ruled and international organizations, taking into account the level of development of each country.</p>
      </div>
    </>
  )
}

export default TargetFocus